import { Add, Menu } from '@mui/icons-material';
import { Box, Drawer, IconButton, Stack } from '@mui/material';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { MenuItem, MenuOption } from './menu-option/menu-option';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Workspace } from '../../state/types';
import { MenuItemSubMenuItem } from './menu-option/sub-menu/sub-menu';
import { NavMenuLabels } from './labels/nav-menu-labels';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';

const NavMenuOptions = (props: { options: MenuItem[] }) => {
  const { options } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        width: { xs: '237px', md: '100%' },
        height: { md: '100%' },
      }}
    >
      {options.map((option) => (
        <MenuOption key={`NavMenu${option.id}`} option={option} />
      ))}
    </Box>
  );
};

export const NavMenu = ({ hideWorkspaces }: { hideWorkspaces?: boolean }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();

  const { organization } = useActiveOrganization();
  const { workspace, setWorkspace } = useActiveWorkspace();

  const onWorkspaceClick = useCallback(
    (workspace: Workspace) => () => {
      setWorkspace(workspace);
      navigate(
        getRoutePathFromParams(RoutePaths.Workspace, {
          organizationName: organization?.name,
          workspaceName: workspace?.name,
        }),
      );
      setIsMenuOpen(false);
    },
    [navigate, organization?.name, setWorkspace],
  );

  const toggleMenu = useCallback(() => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const workspaces = useMemo(() => {
    if (!organization) {
      return [];
    }
    let workspaces: Workspace[] = [];
    if (workspace) {
      const remainingWorkspaces = organization.workspaces.filter((p) => p.id !== workspace.id);
      workspaces = [workspace, ...remainingWorkspaces];
    }
    return workspaces.map((p, index) => ({
      label: p.name,
      value: p.id,
      onItemClick: onWorkspaceClick(p),
      active: index === 0,
    }));
  }, [onWorkspaceClick, workspace, organization]);

  const workspacesList: MenuItemSubMenuItem[] = useMemo(
    () => [
      ...workspaces,
      {
        label: f({ id: 'add-new-workspace' }),
        value: '4',
        hasSeparatorAbove: true,
        Icon: Add,
        onItemClick: () => {
          closeMenu();
          navigate(getRoutePathFromParams(RoutePaths.CreateWorkspace, { organizationName: organization?.name }));
        },
      },
    ],
    [closeMenu, f, navigate, workspaces, organization?.name],
  );

  const options: MenuItem[] = useMemo(() => {
    const navOptions = [
      {
        id: 'workspaces',
        label: workspacesList[0].label,
        value: workspacesList[0].value,
        subMenu: { list: workspacesList },
      },
    ];
    return navOptions;
  }, [workspacesList]);

  return (
    <Box height="100%">
      <IconButton
        data-testid="nav-menu-button"
        onClick={toggleMenu}
        sx={{
          display: { md: 'none' },
          color: 'primary.contrastText',
        }}
      >
        <Menu color="inherit" />
      </IconButton>
      {!hideWorkspaces ? (
        <Fragment>
          <Stack direction="row" height="100%">
            <Box sx={{ display: { xs: 'none', md: 'block' }, height: { md: 64 } }}>
              <NavMenuOptions options={options} />
            </Box>
            <Box
              sx={{
                height: '100%',
                display: { xs: 'none', md: 'block' },
              }}
            >
              <NavMenuLabels />
            </Box>
          </Stack>
          <Drawer anchor="left" open={isMenuOpen} onClose={toggleMenu}>
            <NavMenuOptions options={options} />
            <NavMenuLabels
              buttonSx={{
                width: '100%',
                justifyContent: 'flex-start',
              }}
            />
          </Drawer>
        </Fragment>
      ) : null}
    </Box>
  );
};
