import { EntityType } from '../../entities/EntityType';
import { EntityBase } from '../../entities/EntityBase';

export type ExplorerResult = {
  items: ExplorerItem[];
  error?: string;
};

export class ExplorerItem {
  constructor(
    public readonly entity: EntityBase,
    public readonly id: string,
    public readonly name: string,
    public readonly entityType: EntityType,
    public readonly parentId: string = undefined!,
    //public parent: ExplorerItem = undefined,
    public isHighlighted: boolean = false,
    public isSelected: boolean = false,
  ) {}
}
