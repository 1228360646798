import { Alert, Stack } from '@mui/material';
import { AssetAccordion } from './asset-accordion';
import { DeleteAssetModal } from '../../delete-asset-modal/delete-asset-modal';
import { AssetExplorerResult } from '@xspecs/single-source-model/src/read-models/asset-explorer/AssetExplorerItem';
import { useIntl } from 'react-intl';

export type AssetExplorerProps = {
  assets: AssetExplorerResult;
};

export const AssetExplorer = (props: AssetExplorerProps) => {
  const { assets } = props;
  const { formatMessage: f } = useIntl();

  return (
    <>
      <Stack gap={1}>
        {!assets.length ? (
          <Alert severity="info" sx={{ pl: 1.1 }}>
            {f({ id: 'no-assets-found' })}
          </Alert>
        ) : null}
        {assets.map((asset) => (
          <AssetAccordion key={asset.id} {...asset} />
        ))}
      </Stack>
      <DeleteAssetModal />
    </>
  );
};
