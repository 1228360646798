import { Modal } from '../../modal/modal';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useCallback, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { OrganizationVersionsList } from '../../file-versions-list/organization-versions-list';
import { useMutation, useQuery } from '@apollo/client';
import { VERSIONS_QUERY } from '../../../graphql/queries';
import { RESTORE_VERSION_MUTATION } from '../../../graphql/mutations';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { LoadingButton } from '@mui/lab';
import { Refresh } from '@mui/icons-material';
import { Loading } from '../../loading/loading';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const RestorePreviousVersionModal = () => {
  const { formatMessage: f } = useIntl();

  const { addToast } = useSnackStack();

  const [selectedVersion, setSelectedVersion] = useState<string | null>(null);
  const { organization } = useActiveOrganization();
  const organizationId = organization?.id;
  const { workspace } = useActiveWorkspace();
  const workspaceId = workspace?.id;
  const showRestorePreviousVersionModal = useSingleSourceStore.use.showRestorePreviousVersionModal();
  const setShowRestorePreviousVersionModal = useSingleSourceStore.use.setShowRestorePreviousVersionModal();

  const {
    data,
    loading: loadingVersions,
    refetch,
  } = useQuery(VERSIONS_QUERY, {
    variables: { organizationId },
    onError: () => addToast({ message: f({ id: 'error-loading-versions' }), severity: 'error' }),
    skip: !showRestorePreviousVersionModal,
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const [restoreVersion, { loading: restoringVersion }] = useMutation(RESTORE_VERSION_MUTATION, {
    onError: () => addToast({ message: f({ id: 'error-restoring-version' }), severity: 'error' }),
  });

  const onClose = useCallback(() => {
    setShowRestorePreviousVersionModal(false);
    setSelectedVersion(null);
  }, [setShowRestorePreviousVersionModal]);

  const onVersionSelect = useCallback((versionId: string) => {
    setSelectedVersion(versionId);
  }, []);

  const onVersionPreview = useCallback((versionId: string) => {
    const urlObj = new URL(window.location.href);
    const params = urlObj.searchParams;
    if (params.has('version')) params.set('version', versionId);
    else params.append('version', versionId);
    window.open(urlObj.toString(), '_blank');
  }, []);

  const onRestoreConfirm = useCallback(async () => {
    if (!selectedVersion) return;
    const result = await restoreVersion({
      variables: { args: { organizationId, fileVersion: selectedVersion, scope: workspaceId } },
    });
    if (!result.data) return;
    if (result.data.restoreVersion.error) {
      addToast({ message: f({ id: result.data.restoreVersion.error }), severity: 'error' });
    } else {
      addToast({ message: f({ id: 'restore-previous-version-success' }), severity: 'success' });
      window.location.reload();
    }
  }, [addToast, f, organizationId, restoreVersion, selectedVersion, workspaceId]);

  return (
    <Modal
      id="SSMUploadFile"
      isOpen={showRestorePreviousVersionModal}
      onClose={onClose}
      headerStartContent={
        selectedVersion ? (
          <Typography variant="h6">{f({ id: 'restore-previous-version-question' })}</Typography>
        ) : (
          <Stack>
            <Typography variant="h6">{f({ id: 'restore-previous-version' })}</Typography>
            <Typography variant="caption">{f({ id: 'restore-previous-version-description' })}</Typography>
          </Stack>
        )
      }
      headerSxOverrides={{ justifyContent: 'space-between', alignItems: 'center' }}
      rootSxOverrides={{ width: 600 }}
    >
      {loadingVersions ? (
        <Loading rootProps={{ height: 300, width: '100%' }} circularProgressProps={{ size: 18 }} />
      ) : selectedVersion ? (
        <Stack gap={1}>
          <Typography variant="body1">{f({ id: 'restore-previous-version-confirmation' })}</Typography>
          <Stack direction="row" gap={1} alignSelf="flex-end">
            <Button size="small" onClick={onClose}>
              {f({ id: 'cancel' })}
            </Button>
            <LoadingButton
              disabled={!selectedVersion || !workspaceId || !organizationId}
              size="small"
              variant="contained"
              color="error"
              onClick={onRestoreConfirm}
              loading={restoringVersion}
            >
              {f({ id: 'restore' })}
            </LoadingButton>
          </Stack>
        </Stack>
      ) : !data || data.versions.length === 0 ? (
        <Typography variant="caption">{f({ id: 'no-versions-to-restore' })}</Typography>
      ) : (
        <Stack alignItems="flex-end" gap={1}>
          <Box height={300} width="100%">
            <OrganizationVersionsList
              onVersionSelect={onVersionSelect}
              onVersionPreview={onVersionPreview}
              versions={data?.versions ?? []}
            />
          </Box>
        </Stack>
      )}
      <Box ml="auto">
        <LoadingButton
          size="small"
          onClick={() => refetch()}
          startIcon={<Refresh />}
          loadingPosition="start"
          loading={loadingVersions}
        >
          {f({ id: 'refresh' })}
        </LoadingButton>
      </Box>
    </Modal>
  );
};
