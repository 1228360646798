import { Box, IconButton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { SidebarTab, SidebarTabs, SidebarTabValues } from './sidebar-tabs/sidebar-tabs';
import { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { AssetExplorer } from './asset-explorer/asset-explorer';
import { ProfileDetails } from './profile-details/profile-details';
import { TabContext, TabPanel } from '@mui/lab';
import { AssetExplorerState } from '@xspecs/single-source-model/src/read-models/asset-explorer/AssetExplorerItem';
import { AssetControls } from './asset-controls/asset-controls';
import { Icon } from '../../icons/icon';

export type SidebarProps = {
  assetExplorer: AssetExplorerState;
  profile: {
    name: string;
    email: string;
    picture: string;
  };
  collapsed: boolean;
  onCollapse: () => void;
};

const tabs: SidebarTab[] = [
  {
    value: SidebarTabValues.Assets,
    label: 'Assets',
  },
];

export const Sidebar = (props: SidebarProps) => {
  const { assetExplorer, profile, collapsed, onCollapse } = props;

  const [tab, setTab] = useState<SidebarTabValues>(SidebarTabValues.Assets);

  const handleTabChange = useCallback((event: SyntheticEvent, newTab: SidebarTabValues) => {
    setTab(newTab);
  }, []);

  const explorerWrapperSx: SxProps = useMemo(
    () => ({ px: collapsed ? 1.5 : 2, flex: 1, pb: 1, overflowY: 'auto', overflowX: 'visible' }),
    [collapsed],
  );

  const openAssetExplorer = useCallback(() => {
    onCollapse();
    setTab(SidebarTabValues.Assets);
  }, [onCollapse]);

  return (
    <Stack sx={sidebarSx} width={collapsed ? '64px' : '100%'}>
      <Box sx={logoSx}>{/*<Logo />*/}</Box>
      <Box sx={controlsWrapperSx}>
        {!collapsed ? <AssetControls /> : null}
        <IconButton sx={backButtonSx} onClick={onCollapse}>
          <Icon name={collapsed ? 'show-sidebar' : 'hide-sidebar'} width={24} height={24} />
        </IconButton>
      </Box>

      <Box sx={tabsWrapperSx}>
        {!collapsed ? <SidebarTabs tabs={tabs} onChange={handleTabChange} value={tab} /> : null}
      </Box>

      <Box sx={explorerWrapperSx}>
        <TabContext value={tab}>
          <TabPanel value={SidebarTabValues.Assets} sx={{ p: 0 }}>
            {collapsed ? (
              <IconButton sx={assetExplorerButtonSx} onClick={openAssetExplorer}>
                <Icon name="asset-explorer" width={24} height={24} />
              </IconButton>
            ) : (
              <AssetExplorer assets={assetExplorer.results} />
            )}
          </TabPanel>
          <TabPanel value={SidebarTabValues.Explorer} sx={{ p: 0 }}>
            <Typography variant="caption">Coming Soon!</Typography>
          </TabPanel>
        </TabContext>
      </Box>
      <Box>
        <ProfileDetails {...profile} collapsed={collapsed} />
      </Box>
    </Stack>
  );
};

const sidebarSx: SxProps = {
  height: '100%',
  position: 'relative',
};

const logoSx: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  // pt: 3,
  // pr: 2,
  // pb: 5,
  // pl: 2,
};

const backButtonSx: SxProps<Theme> = {
  borderRadius: 2,
  width: 28,
  height: 28,
  color: 'text.primary',
  mx: 'auto',
  alignSelf: 'center',
  padding: 0,
};

const controlsWrapperSx: SxProps<Theme> = { display: 'flex', px: 2, mb: 1.5, mt: 2, gap: 1 };

const tabsWrapperSx: SxProps = {
  px: 2,
  mb: 1,
};

const assetExplorerButtonSx: SxProps<Theme> = {
  borderRadius: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: (theme) => theme.palette.grey[50],
};
