import { FC } from 'react';
import { IconComponentProps } from '../icon';

export const AssetExplorer: FC<IconComponentProps> = () => {
  return (
    <>
      <rect width="16" height="8" rx="2" fill="white" />
      <rect x="0.5" y="0.5" width="15" height="7" rx="1.5" stroke="black" strokeOpacity="0.54" />
      <rect x="2" y="2" width="4" height="4" fill="#D9D9D9" />
      <rect x="2" y="5" width="16" height="8" rx="2" fill="white" />
      <rect x="2.5" y="5.5" width="15" height="7" rx="1.5" stroke="black" strokeOpacity="0.54" />
      <circle cx="6" cy="9" r="2" fill="#D9D9D9" />
      <rect x="4" y="10" width="16" height="8" rx="2" fill="white" />
      <rect x="4.5" y="10.5" width="15" height="7" rx="1.5" stroke="black" strokeOpacity="0.54" />
      <rect x="6" y="12" width="4" height="4" fill="#D9D9D9" />
    </>
  );
};
