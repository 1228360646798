import { NackErrors } from './NackErrors';
export class Errors extends NackErrors {
  static readonly OrganizationNotFound: string = 'ORGANIZATION_NOT_FOUND';
  static readonly OrganizationNameContainsInvalidCharacters: string = 'ORGANIZATION_NAME_CONTAINS_INVALID_CHARS';
  static readonly WorkspaceNameAlreadyExists: string = 'WORKSPACE_NAME_ALREADY_EXISTS';
  static readonly OrganizationNameAlreadyExists: string = 'ORGANIZATION_NAME_ALREADY_EXISTS';
  static readonly UserAlreadyExists: string = 'USER_ALREADY_EXISTS';
  static readonly UserNotFound: string = 'USER_NOT_FOUND';
  static readonly DocNotFound: string = 'DOCUMENT_NOT_FOUND';
  static readonly WorkspaceNotFound: string = 'WORKSPACE_NOT_FOUND';
  static readonly ChangesetNotFound: string = 'CHANGESET_NOT_FOUND';
  static readonly ChangesetAlreadyDeleted: string = 'CHANGESET_ALREADY_DELETED';
  static readonly ChangesetAlreadyPublished: string = 'CHANGESET_ALREADY_PUBLISHED';
  static readonly ChangesetAlreadyUnPublished: string = 'CHANGESET_ALREADY_UNPUBLISHED';
  static readonly BlockAlreadyAddedToChangeset: string = 'BLOCK_ALREADY_ADDED_TO_CHANGESET';
  static readonly SpecNotFound: string = 'SPEC_NOT_FOUND';
  static readonly FileNotFound: string = 'FILE_NOT_FOUND';
  static readonly InvitationNotFound: string = 'INVITATION_NOT_FOUND';
  static readonly Forbidden: string = 'FORBIDDEN';
  static readonly FileAlreadyExists: string = 'FILE_ALREADY_EXISTS';
  static readonly OrganizationEmailDomainNotAllowed: string = 'ORGANIZATION_EMAIL_DOMAIN_NOT_ALLOWED';
  static readonly ParentNotFound: string = 'PARENT_NOT_FOUND';
  static readonly SamlSsoAlreadyConfigured: string = 'SAML_SSO_ALREADY_CONFIGURED';
  static readonly FileNameTooLong: string = 'FILE_NAME_TOO_LONG';
  static readonly CommentAlreadyExists: string = 'COMMENT_ALREADY_EXISTS';
  static readonly CommentNotFound: string = 'COMMENT_NOT_FOUND';
  static readonly UserMentionAlreadyExists: string = 'USER_MENTION_ALREADY_EXISTS';
  static readonly CapabilityCannotContainCapabilities = 'CAPABILITY_CANNOT_CONTAIN_CAPABILITIES';
  static readonly SpecCannotContainChildren: string = 'SPEC_CANNOT_CONTAIN_CHILDREN';
  static readonly DocsCanOnlyContainSpecs: string = 'DOCS_CAN_ONLY_CONTAIN_SPECS';
  static readonly FeatureSetCannotContainFeatureSetsOrCapabilities: string =
    'FEATURE_SET_CANNOT_CONTAIN_FEATURE_SETS_OR_CAPABILITIES';
  static readonly FeatureCannotContainCapabilitiesOrRequirements: string =
    'FEATURE_CANNOT_CONTAIN_CAPABILITIES_OR_REQUIREMENTS';
  static readonly SingleSourceModelAlreadyExists: string = 'SINGLE_SOURCE_MODEL_ALREADY_EXISTS';
  static readonly EntityNotFound: string = 'ENTITY_NOT_FOUND';
  static readonly EntityNotAnAttachable: string = 'ENTITY_NOT_AN_ATTACHABLE';
  static readonly DuplicateAssetName: string = 'DUPLICATE_ASSET_NAME';
  static readonly DuplicateEntityId: string = 'DUPLICATE_ENTITY_ID';
  static readonly NoResultsFound = 'NO_RESULTS_FOUND';
  static readonly InvalidManifestUrl: string = 'INVALID_MANIFEST_URL';
  static readonly VendorIsRequiredInAppManifest: string = 'VENDOR_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly VendorNameIsRequiredInAppManifest: string = 'VENDOR_NAME_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly VendorWebsiteIsRequiredInAppManifest: string = 'VENDOR_WEBSITE_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly VendorSupportUrlIsRequiredInAppManifest: string = 'VENDOR_SUPPORT_URL_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly NameIsRequiredInAppManifest: string = 'NAME_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly DescriptionIsRequiredInAppManifest: string = 'DESCRIPTION_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly VersionIsRequiredInAppManifest: string = 'VERSION_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly AppUrlIsRequiredInAppManifest: string = 'APP_URL_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly FailedToFetchManifest: string = 'FAILED_TO_FETCH_MANIFEST';
  static readonly ConstructWithMissingTypeInAppManifest: string = 'CONSTRUCT_WITH_MISSING_TYPE_IN_APP_MANIFEST';
  static readonly ConstructWithMissingCaptionInAppManifest: string = 'CONSTRUCT_WITH_MISSING_CAPTION_IN_APP_MANIFEST';
  static readonly ConstructWithMissingBackgroundColorInAppManifest: string =
    'CONSTRUCT_WITH_MISSING_BACKGROUND_COLOR_IN_APP_MANIFEST';
  static readonly ConstructWithMissingTextColorInAppManifest: string =
    'CONSTRUCT_WITH_MISSING_TEXT_COLOR_IN_APP_MANIFEST';
  static readonly ConstructWithMissingShapeInAppManifest: string = 'CONSTRUCT_WITH_MISSING_SHAPE_IN_APP_MANIFEST';
  static readonly AssetWithMissingCaptionInAppManifest: string = 'ASSET_WITH_MISSING_CAPTION_IN_APP_MANIFEST';
  static readonly AssetWithMissingTypeInAppManifest: string = 'ASSET_WITH_MISSING_TYPE_IN_APP_MANIFEST';
  static readonly AssetWithMissingIconInAppManifest: string = 'ASSET_WITH_MISSING_ICON_IN_APP_MANIFEST';
  static readonly AssetWithMissingDataSourceInAppManifest: string = 'ASSET_WITH_MISSING_DATA_SOURCE_IN_APP_MANIFEST';
  static readonly AppAlreadyRegistered: string = 'APP_ALREADY_REGISTERED';
  static readonly IconIsRequiredInAppManifest: string = 'ICON_IS_REQUIRED_IN_APP_MANIFEST';
  static readonly InvalidDetailPaneInAppManifest: string = 'INVALID_DETAIL_PANE_IN_APP_MANIFEST';
}
