import { CommandBase } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { CommandError } from '../../ErrorStore';
import { GqlEntityBase } from '../../entities/gql-entities/GqlEntityBase';
import { Query } from '../../entities/assets/Query';
import { GqlField } from '../../entities/gql-entities/GqlField';
import { EntityParserFactory } from '../../entities/constructs/EntityParserFactory';
import { sid } from '@xspecs/short-id';
import { EntityType } from '../../entities/EntityType';
import { GqlOperation } from '../../entities/gql-entities/GqlOperation';

type UpdateGqlEntityParams = {
  entityId: string;
  width?: number;
  height?: number;
  y?: number;
  x?: number;
  selected?: boolean;
  name?: string;
};

type EntityResizedParams = {
  entityId: string;
} & Partial<UpdateGqlEntityParams>;

export class GqlEntityUpdatedCommand extends EventBase {
  static eventType = 'GqlEntityUpdatedCommand';

  constructor(public readonly params: EntityResizedParams, public readonly source = UpdateGqlEntityCommand) {
    super();
  }
}

export class UpdateGqlEntityCommand extends CommandBase<UpdateGqlEntityParams> {
  execute(params: UpdateGqlEntityParams): GqlEntityUpdatedCommand | CommandError {
    const entity = this.model.entityRepository.get(params.entityId);

    if (!(entity instanceof GqlEntityBase) && !(entity instanceof Query))
      return CommandError.of(new Error('Entity is not a GqlEntity'), 'error');

    if (entity instanceof Query) {
      this.handleQueryAnnotation(entity, params);
    }

    if (entity instanceof GqlEntityBase) {
      this.handleGqlEntity(entity, params);
    }

    return new GqlEntityUpdatedCommand({ ...params, entityId: entity.id });
  }

  private handleQueryAnnotation(entity: Query, params: UpdateGqlEntityParams) {
    entity.annotation = {
      ...entity.annotation,
      width: params.width ?? entity.annotation.width,
      height: params.height ?? entity.annotation.height,
      position: {
        x: params.x ?? entity.annotation.position.x,
        y: params.y ?? entity.annotation.position.y,
      },
    };

    this.model.entityRepository.update(entity);
  }

  private handleGqlEntity(entity: GqlEntityBase, params: UpdateGqlEntityParams) {
    entity.width = params.width ?? entity.width;
    entity.height = params.height ?? entity.height;
    entity.position.x = params.x ?? entity.position.x;
    entity.position.y = params.y ?? entity.position.y;
    entity.isSelected = params.selected ?? entity.isSelected;

    // Remove all space characters
    params.name = params.name?.replace(/\s+/g, '');
    if (params.name && entity.name !== params.name) {
      if (params.name.includes('.')) {
        this.addOrUpdateGqlEntitiesBasedOnPath(params, entity);
      } else {
        entity.name = params.name ?? '';
      }
      const query = entity.query!;
      query.syncGqlEntitiesToQuery();
      //entity.query.syncQueryToGqlEntities(entity.query.queryText);
      this.model.entityRepository.update(query);
    }

    this.model.entityRepository.update(entity);
  }

  private addOrUpdateGqlEntitiesBasedOnPath(params: UpdateGqlEntityParams, entity: GqlEntityBase) {
    const newPathSegments = params.name!.split('.');
    const originalPath = entity.path;
    const originalPathSegments = originalPath ? originalPath.split('.') : [];

    entity.name = newPathSegments[newPathSegments.length - 1];

    const originalParentPath = originalPathSegments.slice(0, -1).join('.');
    const newParentPath = newPathSegments.slice(0, -1).join('.');

    const query = entity.query!;
    const parent: GqlEntityBase | Query = query.gqlEntities.find((e) => e instanceof GqlOperation) as GqlEntityBase;

    const updatedEntities: GqlEntityBase[] = [];
    let currentParent = parent;

    for (let i = 0; i < newPathSegments.length - 1; i++) {
      const segment = newPathSegments[i];
      const originalSegment = originalPathSegments[i] || segment;

      let gqlField = query.gqlEntities.find((e) => e.name === segment && e.parent === currentParent) as GqlField;

      // If a field name is duplicated on two levels, (for example fieldName.fieldName) this prevents setting entity as its own parent
      if (gqlField === entity) {
        gqlField = undefined!;
      }

      if (!gqlField && originalSegment !== segment) {
        gqlField = query.gqlEntities.find((e) => e.name === originalSegment && e.parent === currentParent) as GqlField;

        if (gqlField && gqlField !== entity) {
          gqlField.name = segment;
          this.model.entityRepository.update(gqlField);
        }
      }

      if (!gqlField) {
        gqlField = EntityParserFactory.parse<GqlField>({
          id: sid(),
          name: segment,
          type: EntityType.GqlField,
          position: currentParent.position,
          scopes: this.model.entityRepository.getScopes(),
        });

        gqlField.parent = currentParent;

        if (gqlField === currentParent) {
          throw new Error(`Entity ${gqlField.name} cannot be its own parent.`);
        }

        this.addGqlEntityAtCorrectIndex(query, gqlField, updatedEntities);
        this.model.entityRepository.add(gqlField);
      }

      currentParent = gqlField;
      updatedEntities.push(gqlField);
    }

    if (entity === currentParent) {
      throw new Error(`Entity ${entity.name} cannot be its own parent.`);
    }

    entity.parent = currentParent;
    updatedEntities.push(entity);

    if (originalParentPath !== newParentPath) {
      this.removeUnusedParentEntities(query, originalParentPath);
    }

    this.reorderGqlEntities(query, updatedEntities);
  }

  /**
   * Helper function to remove parent entities that no longer have children.
   * @param query The query object containing GQL entities.
   * @param parentPath The path of the parent to check and possibly remove.
   */
  private removeUnusedParentEntities(query: Query, parentPath: string) {
    const parentEntity = query.gqlEntities.find((e) => e.path === parentPath) as GqlField;
    if (parentEntity && !this.hasChildFields(parentEntity)) {
      this.model.entityRepository.delete(parentEntity.id);
      const index = query.gqlEntities.indexOf(parentEntity);
      if (index !== -1) {
        query.gqlEntities.splice(index, 1);
      }
    }
  }

  /**
   * Checks if a parent entity has any child fields in the GQL entities.
   * @param parentEntity The parent entity to check.
   * @returns True if there are child fields, false otherwise.
   */
  private hasChildFields(parentEntity: GqlField): boolean {
    return this.model.entityRepository.list().some((e) => e.parent === parentEntity);
  }

  private getEntityParentByIndex(entity: GqlEntityBase, index: number): GqlEntityBase {
    let currentGqlEntity: GqlEntityBase = entity;
    for (let i = 0; i < index; i++) {
      if (currentGqlEntity && currentGqlEntity.parent && currentGqlEntity.parent instanceof GqlField) {
        currentGqlEntity = currentGqlEntity.parent as GqlEntityBase;
      } else {
        currentGqlEntity = undefined!;
      }
    }
    return currentGqlEntity;
  }

  private addGqlEntityAtCorrectIndex(query: Query, gqlEntity: GqlEntityBase, orderedEntities: GqlEntityBase[]) {
    const index = orderedEntities.length;
    query.gqlEntities.splice(index, 0, gqlEntity);
  }

  private reorderGqlEntities(query: Query, updatedEntities: GqlEntityBase[]) {
    const originalEntities = [...query.gqlEntities];

    const operations = originalEntities.filter((e) => e instanceof GqlOperation);

    // Iterate through updated entities and move them in the original entity list based on parent-child relationships
    updatedEntities.forEach((updatedEntity) => {
      const parentIndex = originalEntities.findIndex((e) => e === updatedEntity.parent);
      if (parentIndex !== -1) {
        let insertIndex = parentIndex + 1;
        // go through siblings to find the correct position for insertion
        while (insertIndex < originalEntities.length && originalEntities[insertIndex].parent === updatedEntity.parent) {
          insertIndex++;
        }
        // remove the entity if it already exists in the list to reinsert it at the correct position
        const existingIndex = originalEntities.indexOf(updatedEntity);
        if (existingIndex !== -1) {
          originalEntities.splice(existingIndex, 1);
        }

        // add the updated entity straight right after the siblings of its parent
        originalEntities.splice(insertIndex, 0, updatedEntity);
      }
    });

    const nonOperations = originalEntities.filter((e) => !(e instanceof GqlOperation));

    // operation always comes first followed by the rest of the entities
    query.gqlEntities = operations.concat(nonOperations);
  }
}
