import { AttachmentType, EntityType } from '../../entities/EntityType';
import { ConstructShape } from '../../apps/DynamicEntityRegistry';
import { UploadType } from '../../entities/assets/Upload';

export type AssetExplorerState = {
  results: AssetExplorerResult;
  searchQuery: string;
  sortQuery: AssetSort;
};

export enum AssetSort {
  Newest = 'newest',
  Ascending = 'ascending',
  Descending = 'descending',
}

export type AssetExplorerResult = AssetExplorerItem[];

export type AssetExplorerUsageItem = {
  id: string;
  type: EntityType;
  name: string;
  shape: ConstructShape;
};

export class AssetExplorerItem {
  constructor(
    public readonly id: string,
    public readonly name: string,
    public readonly type: AttachmentType,
    public usages: AssetExplorerUsageItem[],
    public url?: string,
    public uploadType?: UploadType | undefined,
  ) {}
}
