import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { EntityChanges } from '../../types';
import { AttachmentType, EntityType, isValidAttachmentType } from '../../entities/EntityType';
import { AssetExplorerResult, AssetSort } from './AssetExplorerItem';
import { AssetBase } from '../../entities/assets/AssetBase';
import { Attachment } from '../../entities/assets/Attachment';
import { ConstructBase } from '../../entities/constructs/ConstructBase';
import { ConstructShape } from '../../apps/DynamicEntityRegistry';
import { Upload, UploadType } from '../../entities/assets/Upload';
import Fuse from 'fuse.js';

type AssetItem = { id: string; name: string; type: AttachmentType; uploadType?: UploadType | undefined; url?: string };
type ConstructItem = { id: string; name: string; type: EntityType; attachments: Attachment[]; shape: ConstructShape };

export class AssetExplorer {
  constructor(public readonly entityRepository: EntityRepository, public readonly store: IStore) {}

  assets: Record<string, AssetItem> = {};
  constructs: Record<string, ConstructItem> = {};

  results: AssetExplorerResult = [];
  search: string = '';
  sort: AssetSort = AssetSort.Newest;

  update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    this.handleAdditions(changes);
    this.handleUpdates(changes);
    this.handleDeletions(changes);
  }

  private handleUpdates(changes: EntityChanges) {
    changes.updated.forEach((update) => {
      if (update.entity instanceof AssetBase) {
        this.assets[update.entity.id] = {
          id: update.entity.id,
          name: update.entity.name,
          type: AttachmentType[update.entity.type],
          uploadType: update.entity instanceof Upload ? update.entity.subType : undefined,
          url: update.entity instanceof Upload ? update.entity.url : undefined,
        };
      }
      if (!isValidAttachmentType(update.entity.type) && !(update.entity instanceof Attachment)) {
        const entity = update.entity as ConstructBase;
        this.constructs[update.entity.id] = {
          id: entity.id,
          name: entity.name,
          type: EntityType[entity.type],
          attachments: entity.attachments,
          shape: entity.width === entity.height ? ConstructShape.Square : ConstructShape.Rectangle,
        };
      }
    });
    this.updateStore();
  }

  private handleAdditions(changes: EntityChanges) {
    changes.added.forEach((entity) => {
      if (entity instanceof AssetBase) {
        this.assets = {
          [entity.id]: {
            id: entity.id,
            name: entity.name,
            type: AttachmentType[entity.type],
            uploadType: entity instanceof Upload ? entity.subType : undefined,
            url: entity instanceof Upload ? entity.type : undefined,
          },
          ...this.assets,
        };
      }
      if (!isValidAttachmentType(entity.type) && !(entity instanceof Attachment)) {
        const constructEntity = entity as ConstructBase;
        this.constructs = {
          [entity.id]: {
            id: constructEntity.id,
            name: constructEntity.name,
            type: EntityType[constructEntity.type],
            attachments: constructEntity.attachments,
            shape: entity.width === entity.height ? ConstructShape.Square : ConstructShape.Rectangle,
          },
          ...this.constructs,
        };
      }
    });
    this.updateStore();
  }

  private handleDeletions(changes: EntityChanges) {
    changes.deleted.forEach((entity) => {
      delete this.assets[entity.id];
      delete this.constructs[entity.id];
    });

    this.updateStore();
  }

  private updateStore() {
    let results: AssetExplorerResult = Object.values(this.assets).map((asset) => {
      return {
        ...asset,
        usages: Object.values(this.constructs).filter((construct) =>
          construct.attachments?.some((attachment) => attachment.asset?.id === asset.id),
        ),
      };
    });

    if (this.search) {
      results = this.applySearch(results);
    }

    if (this.sort) {
      results = this.applySort(results);
    }

    this.results = results;
    this.store.getState().setAssetExplorer({
      results: this.results,
      searchQuery: this.search,
      sortQuery: this.sort,
    });
  }

  private applySearch(results: AssetExplorerResult): AssetExplorerResult {
    const fuse = new Fuse(results, { keys: ['name'], threshold: 0.4 });
    return fuse.search(this.search).map((fuseItem) => fuseItem.item);
  }

  public updateSearch(search: string) {
    this.search = search.toLowerCase();
    this.updateStore();
  }

  private handleSortAscending(a: { name: string }, b: { name: string }) {
    return a.name.localeCompare(b.name);
  }

  private handleSortDescending(a: { name: string }, b: { name: string }) {
    return b.name.localeCompare(a.name);
  }

  private applySort(results: AssetExplorerResult): AssetExplorerResult {
    switch (this.sort) {
      case AssetSort.Newest:
        return results;
      case AssetSort.Ascending:
        return results.sort(this.handleSortAscending).map((asset) => ({
          ...asset,
          usages: asset.usages.sort(this.handleSortAscending),
        }));
      case AssetSort.Descending:
        return results.sort(this.handleSortDescending).map((asset) => ({
          ...asset,
          usages: asset.usages.sort(this.handleSortDescending),
        }));
      default:
        return results;
    }
  }

  public updateSort(sort: AssetSort) {
    this.sort = sort;
    this.updateStore();
  }

  public clear() {
    this.results = [];
    this.search = '';
    this.sort = AssetSort.Newest;
    this.store.getState().setAssetExplorer({});
  }
}
