import { Box, ListItemButton, Typography } from '@mui/material';
import { PaletteAttachment } from '@xspecs/single-source-model';
import { DragEventHandler, useCallback, useRef } from 'react';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';

type PaletteIconItemProps = PaletteAttachment;

export const PaletteIconItem = ({ type, caption, icon }: PaletteIconItemProps) => {
  const previewRef = useRef(null);

  const setConstructToInsert = useSingleSourceStore.use.setConstructToInsert();

  const onDragStart = useCallback<DragEventHandler>(
    (event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
      if (previewRef.current) event.dataTransfer.setDragImage(previewRef.current, 0, 0);
    },
    [type],
  );

  const onClick = useCallback(() => {
    setConstructToInsert(type);
  }, [setConstructToInsert, type]);

  return (
    <ListItemButton
      disableRipple
      disableTouchRipple
      sx={{
        height: 25,
        gap: 1,
        maxWidth: 'fit-content',
        p: 1,
      }}
      draggable
      onDragStart={onDragStart}
      onClick={onClick}
    >
      <Box>
        <img
          ref={previewRef}
          width={24}
          height={24}
          style={{
            width: 24,
            height: 24,
            minWidth: 24,
            minHeight: 24,
          }}
          src={icon}
        />
      </Box>
      <Typography>{caption}</Typography>
    </ListItemButton>
  );
};
