import { Workspace } from '../state/types';
import { useSingleSourceStore } from '../store/single-source-store/single-source-store';

export const useActiveWorkspace = () => {
  const workspace: Workspace = useSingleSourceStore.use.workspace();
  const setWorkspace = useSingleSourceStore.use.setWorkspace();

  return {
    workspace,
    setWorkspace,
  } as const;
};
