import { MentionCombobox } from '../plate-ui/mention-combobox';
import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { USERS_QUERY } from '../../graphql/queries';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export const MentionUserCombobox = () => {
  const { organization } = useActiveOrganization();

  const { data } = useQuery(USERS_QUERY, { variables: { organizationId: organization?.id }, skip: !organization });

  const mentionables = useMemo(() => data?.users.map((user) => ({ key: user.id, text: user.name })), [data?.users]);

  return <MentionCombobox items={mentionables} />;
};
