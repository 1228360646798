import { Box, Button, SxProps, Theme, Typography } from '@mui/material';
import { AspectRatio } from '../../single-source-model/palette/item/palette-construct-list-item';
import { MouseEventHandler, useCallback, useMemo } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { AssetExplorerUsageItem } from '@xspecs/single-source-model/src/read-models/asset-explorer/AssetExplorerItem';

export const AssetUsageItem = (props: AssetExplorerUsageItem) => {
  const { id, name, type, shape } = props;

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();
  const { entityId } = useParams();

  const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const path = getRoutePathFromParams(RoutePaths.Entity, {
        organizationName: organization?.name,
        workspaceName: workspace?.name,
        entityId: id,
      });

      setConstructToPanTo(id);
      navigate(path);
    },
    [id, navigate, organization?.name, setConstructToPanTo, workspace?.name],
  );

  const usageSx: SxProps = useMemo(
    () => ({
      py: 0.5,
      pl: 3,
      pr: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: 1,
      borderLeft: '1px solid',
      borderColor: entityId === id ? 'primary.main' : 'action.hover',
      textAlign: 'left',
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      backgroundColor: entityId === id ? 'other.selectedEntity' : 'none',
    }),
    [entityId, id],
  );

  return (
    <Button key={name} sx={usageSx} onClick={onClick}>
      <Box
        height={10}
        className={type}
        sx={{
          aspectRatio: AspectRatio[shape],
          border: '1px solid',
          borderColor: 'action.disabled',
          mr: shape === 'square' ? 1 : 0,
        }}
      />
      <Typography variant="caption" color="textSecondary" textTransform="none" sx={usageNameSx}>
        {name}
      </Typography>
    </Button>
  );
};

const usageNameSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '80%',
};
