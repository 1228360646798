import { UpgradeRounded } from '@mui/icons-material';
import { Button } from '@mui/material';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const UpgradeButton = () => {
  const { formatMessage: f } = useIntl();

  const navigate = useNavigate();

  const { organization } = useActiveOrganization();

  const onUpgrade = useCallback(() => {
    navigate(getRoutePathFromParams(RoutePaths.Settings, { organizationName: organization?.name }));
  }, [navigate, organization?.name]);

  if (organization.hasSubscription) {
    return null;
  }

  return (
    <Button
      sx={{ mr: 1, display: { xs: 'none', md: 'flex' } }}
      startIcon={<UpgradeRounded />}
      onClick={onUpgrade}
      size="small"
      variant="contained"
    >
      {f({ id: 'upgrade' })}
    </Button>
  );
};
