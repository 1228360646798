import { AttachmentType, EntityType } from '../entities/EntityType';
import { DynamicAttachment, DynamicConstruct, ConstructShape } from '../apps/DynamicEntityRegistry';
import { IStore } from '../data/Store';
import { Attachment } from '../entities/assets/Attachment';

export type PaletteConstruct = DynamicConstruct;
export type PaletteConstructsColumn = {
  caption: string;
  entities: PaletteConstruct[];
};
export type PaletteConstructsRow = PaletteConstructsColumn[];

export type PaletteAttachment = Omit<DynamicAttachment, 'dataSource'>;
export type PaletteAttachmentRow = PaletteAttachment[];

export type PaletteLayout = [PaletteConstructsRow, PaletteAttachmentRow];

export class Palette {
  private readonly constructs: PaletteConstructsRow;
  private readonly attachments: PaletteAttachmentRow;

  constructor(private readonly store: IStore) {
    this.constructs = [
      {
        caption: 'Information Flow',
        entities: [
          {
            type: EntityType.Command,
            caption: 'Command',
            backgroundColor: '#ACD0F7',
            shape: ConstructShape.Square,
            textColor: '#000000',
          },
          {
            type: EntityType.Data,
            caption: 'Data',
            backgroundColor: '#C1E558',
            shape: ConstructShape.Square,
            textColor: '#000000',
          },
          {
            type: EntityType.Event,
            caption: 'Event',
            backgroundColor: '#FF9C48',
            shape: ConstructShape.Square,
            textColor: '#000000',
          },
        ],
      },
      {
        caption: 'Business Process Model',
        entities: [
          {
            type: EntityType.Process,
            caption: 'Process',
            backgroundColor: '#C6A2D2',
            shape: ConstructShape.Rectangle,
            textColor: '#000000',
          },
          {
            type: EntityType.Constraints,
            caption: 'Constraints',
            backgroundColor: '#FFF9B1',
            shape: ConstructShape.Rectangle,
            textColor: '#000000',
          },
          {
            type: EntityType.ExternalSystem,
            caption: 'External System',
            backgroundColor: '#FFD0DF',
            shape: ConstructShape.Rectangle,
            textColor: '#000000',
          },
        ],
      },
      {
        caption: 'Software Model',
        entities: [
          {
            type: EntityType.Resolver,
            caption: 'Resolver',
            backgroundColor: '#9BC916',
            shape: ConstructShape.Rectangle,
            textColor: '#000000',
          },
          {
            type: EntityType.ReadModel,
            caption: 'Read Model',
            backgroundColor: '#67C6C0',
            shape: ConstructShape.Rectangle,
            textColor: '#000000',
          },
          {
            type: EntityType.Projection,
            caption: 'Projection',
            backgroundColor: '#FA8A7B',
            shape: ConstructShape.Rectangle,
            textColor: '#000000',
          },
          {
            type: EntityType.Gateway,
            caption: 'Gateway',
            backgroundColor: '#F685A9',
            shape: ConstructShape.Rectangle,
            textColor: '#000000',
          },
        ],
      },
    ];
    this.attachments = [
      {
        type: EntityType.Doc,
        caption: 'Doc',
        icon: Attachment.iconMap[AttachmentType.Doc],
      },
      {
        type: EntityType.Schema,
        caption: 'Schema',
        icon: Attachment.iconMap[AttachmentType.Schema],
      },
      {
        type: EntityType.Query,
        caption: 'Query',
        icon: Attachment.iconMap[AttachmentType.Query],
      },
    ];

    this.updateStore();
  }

  get layout(): PaletteLayout {
    return [this.constructs.filter((column) => column.entities.length > 0), this.attachments];
  }

  // TODO: these don't belong in a readmodel. smell!
  get registeredConstructs(): string[] {
    return this.constructs.flatMap((column) => {
      return column.entities.map((entity) => entity.type);
    });
  }

  get registeredAttachments(): string[] {
    return this.attachments.map((attachment) => attachment.type);
  }

  addConstructs(constructsToAdd: PaletteConstruct[]) {
    const existingColumn = this.constructs.find((column) => column.caption === 'other');
    if (existingColumn) {
      if (existingColumn.entities.find((existing) => constructsToAdd.some((added) => added.type === existing.type))) {
        return;
      }
      existingColumn.entities.push(...constructsToAdd);
    } else {
      const uniqueConstructs = constructsToAdd.filter((construct) => {
        return !this.constructs.some((column) => column.entities.some((existing) => existing.type === construct.type));
      });
      this.constructs.push({
        caption: 'other',
        entities: uniqueConstructs,
      });
    }
    this.updateStore();
  }

  addAssets(attachmentsToAdd: DynamicAttachment[]) {
    attachmentsToAdd.forEach((attachment) => {
      if (this.attachments.some((existing) => existing.type === attachment.type)) {
        return;
      }
      this.attachments.push(attachment);
    });
    this.updateStore();
  }

  removeAssets(attachmentsToRemove: { type: string }[]) {
    attachmentsToRemove.forEach((attachment) => {
      const index = this.attachments.findIndex((existing) => existing.type === attachment.type);
      if (index === -1) {
        return;
      }
      this.attachments.splice(index, 1);
    });
    this.updateStore();
  }

  removeConstructs(constructsToRemove: { type: string }[]) {
    this.constructs.forEach((column) => {
      constructsToRemove.forEach((construct) => {
        const index = column.entities.findIndex((existing) => existing.type === construct.type);
        if (index === -1) {
          return;
        }
        column.entities.splice(index, 1);
      });
    });
    this.updateStore();
  }

  isAttachmentInPalette(attachmentType: string): boolean {
    return this.attachments.some((attachment) => attachment.type === attachmentType);
  }

  isValidEntityType(type: any): boolean {
    return this.registeredConstructs.includes(type) || this.registeredAttachments.includes(type);
  }

  static isPaletteConstructsColumn(data: any): data is PaletteConstructsRow {
    return (
      Array.isArray(data) && data.every((item: any) => typeof item.caption === 'string' && Array.isArray(item.entities))
    );
  }

  static isPaletteAttachmentRow(data: any): data is PaletteAttachmentRow {
    return (
      Array.isArray(data) &&
      data.every((item: any) => typeof item.caption === 'string' && typeof item.type === 'string')
    );
  }

  updateStore() {
    this.store.getState().setPalette(this.layout);
  }
}
