import { create } from 'zustand';
import { createSelectors } from '../../common/zustand';
import {
  AssetSort,
  AttachmentType,
  EntityType,
  Mode,
  SingleSourceModel,
  SingleSourceStore,
} from '@xspecs/single-source-model';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { Viewport } from '@xyflow/react';

const singleSourceStore = create<SingleSourceStore>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        assets: {
          [AttachmentType.Upload]: [],
          [AttachmentType.Actor]: [],
          [AttachmentType.Doc]: [],
          [AttachmentType.Query]: [],
          [AttachmentType.Schema]: [],
          [AttachmentType.Spec]: [],
        },
        assetExplorer: {
          results: [],
          searchQuery: '',
          sortQuery: AssetSort.Newest,
        },
        instantMockUrl: 'http://localhost:3001/api',
        mode: Mode.SELECTION,
        constructToInsert: null,
        constructToInsertUsingDrag: null,
        activeUsersByFile: {},
        constructToPanTo: undefined,
        singleSourceModel: null,
        searchQuery: '',
        expandAll: false,
        collapseAll: false,
        showResolvedThreads: false,
        annotator: null,
        isLoaded: false,
        showUploadFileModal: undefined,
        showPreviewFor: undefined,
        states: {},
        graph: { nodes: [], edges: [] },
        explorerResult: { items: [], error: undefined },
        filesById: {},
        labels: [],
        showManageLabelsModal: false,
        unsavedFilter: undefined,
        savedFilters: [],
        appliedSavedFilter: undefined,
        openTagsSelectorFor: undefined,
        showRestorePreviousVersionModal: false,
        entityDetails: null,
        errors: [],
        showUploadLinkModal: false,
        urlToPreview: null,
        showProposeSchemaChangesModal: false,
        viewports: {},
        selectedGraph: {},
        variantProposal: '',
        graphDetails: null,
        loadingVariantsProposal: false,
        schemaOptions: [],
        creatingProposal: false,
        showKeyboardShortcutsModal: false,
        markers: {},
        runningQuery: false,
        seedData: {},
        showWorkspaceResetModal: false,
        workspace: null,
        organization: null,
        showUploadAppModal: false,
        palette: undefined,
        showDeleteAssetModal: false,
        assetToDelete: undefined,
        collapseSidebar: true,

        setShowProposeSchemaChangesModal: (showProposeSchemaChangesModal) => {
          set({ showProposeSchemaChangesModal });
        },
        setSelectionMode: () => {
          set({ mode: Mode.SELECTION, constructToInsert: null });
        },
        setInsertConstructMode: () => {
          set({ mode: Mode.INSERT_CONSTRUCT });
        },
        setInsertCapabilityMode: () => {
          set({ mode: Mode.INSERT_CAPABILITY, constructToInsert: null });
        },
        setInsertMomentMode: () => {
          set({ mode: Mode.INSERT_MOMENT, constructToInsert: null });
        },
        setInsertActionMode: () => {
          set({ mode: Mode.INSERT_ACTION, constructToInsert: null });
        },
        setInsertInterfaceMode: () => {
          set({ mode: Mode.INSERT_INTERFACE, constructToInsert: null });
        },
        setInsertActorMode: () => {
          set({ mode: Mode.INSERT_ACTOR, constructToInsert: null });
        },
        setInsertSpecMode: () => {
          set({ mode: Mode.INSERT_SPEC, constructToInsert: null });
        },
        setInsertThreadMode: () => {
          set({ mode: Mode.INSERT_THREAD, constructToInsert: null });
        },
        setConstructToInsert: (construct) => {
          set({ mode: Mode.INSERT_CONSTRUCT, constructToInsert: construct });
        },
        setActiveUsersByFile: (fileId, activeUsers) => {
          set((state) => ({
            activeUsersByFile: { ...state.activeUsersByFile, [fileId]: activeUsers },
          }));
        },
        setInsertNarrativeMode: () => {
          set({ mode: Mode.INSERT_SCRIPT });
        },
        setConstructToPanTo: (id) => {
          set({ constructToPanTo: id });
        },
        setSingleSourceModel: (model: SingleSourceModel) => {
          set({ singleSourceModel: model });
        },
        setAnnotator: (annotator) => {
          set((state) => ({ annotator: annotator ? { ...state.annotator, ...annotator } : null }));
        },
        setSearchQuery: (searchQuery) => {
          set({ searchQuery });
        },
        setShowResolvedThreads: (showResolvedThreads) => {
          set({ showResolvedThreads });
        },
        setIsLoaded: (isLoaded) => {
          set({ isLoaded });
        },
        setConstructToInsertUsingDrag: (constructToInsertUsingDrag: EntityType) => {
          set({ constructToInsertUsingDrag });
        },
        setShowUploadFileModal: (showUploadFileModal?: string) => {
          set({ showUploadFileModal });
        },
        setStateField: (key, value) => {
          set((state) => ({
            states: { ...state.states, [key]: value },
          }));
        },
        setGraph: (graph) => {
          set({ graph });
        },
        setExplorerResult: (result) => {
          set({ explorerResult: result });
        },
        setFileById: (fileId, file) => {
          set((state) => ({
            filesById: { ...state.filesById, [fileId]: file },
          }));
        },
        setLabels: (labels) => {
          set({ labels });
        },
        setShowManageLabelsModal: (showManageLabelsModal) => {
          set({ showManageLabelsModal });
        },
        setUnsavedFilter: (unsavedFilter) => {
          set({ unsavedFilter });
        },
        setSavedFilters: (savedFilters) => {
          set({ savedFilters });
        },
        setAppliedSavedFilter: (appliedSavedFilter) => {
          set({ appliedSavedFilter });
        },
        setShowRestorePreviousVersionModal: (showRestorePreviousVersionModal) => {
          set({ showRestorePreviousVersionModal });
        },
        setEntityDetails: (entityDetails) => {
          set({ entityDetails });
        },
        setErrors: (errors) => {
          set({ errors: errors });
        },
        setShowUploadLinkModal: (showUploadLinkModal) => {
          set({ showUploadLinkModal });
        },
        setUrlToPreview: (urlToPreview) => {
          set({ urlToPreview });
        },
        setViewports: (key: string, viewport: Viewport) => {
          set((state) => ({
            viewports: { ...state.viewports, [key]: viewport },
          }));
        },
        setInstantMockUrl: (instantMockUrl) => {
          set({ instantMockUrl: instantMockUrl.toLowerCase() });
        },
        setSelectedGraph: (params) => {
          set((state) => {
            const previous = typeof state.selectedGraph === 'string' ? {} : state.selectedGraph;
            if (params.selectedGraph) previous[params.queryId] = params.selectedGraph;
            else delete previous[params.queryId];
            return {
              selectedGraph: { ...previous },
            };
          });
        },
        setVariantProposal: (variantProposal) => {
          set({ variantProposal });
        },
        setGraphDetails: (graphDetails) => {
          set({ graphDetails });
        },
        setLoadingVariantsProposal: (loadingVariantsProposal) => {
          set({ loadingVariantsProposal });
        },
        setAssets: (assets) => {
          set({ assets });
        },
        setAssetExplorer: (assetExplorer) => {
          set({ assetExplorer });
        },
        setSchemaOptions: (schemaOptions) => {
          set({ schemaOptions });
        },
        setCreatingProposal: (creatingProposal) => {
          set({ creatingProposal });
        },
        setShowKeyboardShortcutModal: (showKeyboardShortcutsModal) => {
          set({ showKeyboardShortcutsModal });
        },
        setMarkers: ({ id, marker }) => {
          set((state) => ({
            markers: { ...state.markers, [id]: marker },
          }));
        },
        setRunningQuery: (runningQuery) => {
          set({ runningQuery });
        },
        setSeedData: (seedData = {}) => {
          set({ seedData });
        },
        setShowWorkspaceResetModal: (showWorkspaceResetModal) => {
          set({ showWorkspaceResetModal });
        },
        setOrganization: (organization) => {
          set({ organization });
        },
        setWorkspace: (workspace) => {
          set({ workspace });
        },
        setShowUploadAppModal: (showUploadAppModal) => {
          set({ showUploadAppModal });
        },
        setAppState: (appName, state) => {
          set({ [appName]: state });
        },
        setPalette: (palette) => {
          set({ palette });
        },
        setShowDeleteAssetModal: (showDeleteAssetModal) => {
          set({ showDeleteAssetModal });
        },
        setAssetToDelete: (assetToDelete) => {
          set({ assetToDelete });
        },
        setCollapseSidebar: (collapseSidebar) => {
          set({ collapseSidebar });
        },
      }),
      {
        name: 'single-source-store',
        partialize: (state) => ({
          states: state.states,
          viewports: state.viewports,
          instantMockUrl: state.instantMockUrl,
          collapseSidebar: state.collapseSidebar,
          // selectedGraph: state.selectedGraph,
          // variantProposal: state.variantProposal,
        }),
      },
    ),
  ),
);

const useSingleSourceStore = createSelectors(singleSourceStore);
export { singleSourceStore, useSingleSourceStore };
