import { SingleSourceModelToolbarButton } from '../button';
import { Icon, IconName } from '../../../../icons/icon';
import { EntityType } from '@xspecs/single-source-model';
import { useTheme } from '@mui/material';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { DragEventHandler, useCallback, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

type SingleSourceModelToolbarConstructButtonProps = {
  type:
    | EntityType.Moment
    | EntityType.Action
    | EntityType.Interface
    | EntityType.Actor
    | EntityType.Spec
    | EntityType.Thread
    | EntityType.Upload
    | EntityType.Capability;
};

export const SingleSourceModelToolbarConstructButton = (props: SingleSourceModelToolbarConstructButtonProps) => {
  const { type } = props;

  const { formatMessage: f } = useIntl();

  const previewRef = useRef<SVGSVGElement>(null);

  const theme = useTheme();

  const mode = useSingleSourceStore.use.mode();
  const setInsertMomentMode = useSingleSourceStore.use.setInsertMomentMode();
  const setInsertActionMode = useSingleSourceStore.use.setInsertActionMode();
  const setInsertInterfaceMode = useSingleSourceStore.use.setInsertInterfaceMode();
  const setInsertActorMode = useSingleSourceStore.use.setInsertActorMode();
  const setInsertSpecMode = useSingleSourceStore.use.setInsertSpecMode();
  const setInsertThreadMode = useSingleSourceStore.use.setInsertThreadMode();
  const setConstructToInsertUsingDrag = useSingleSourceStore.use.setConstructToInsertUsingDrag();
  const setInsertCapabilityMode = useSingleSourceStore.use.setInsertCapabilityMode();

  const onClick = useCallback(() => {
    switch (type) {
      case EntityType.Moment:
        setInsertMomentMode();
        break;
      case EntityType.Action:
        setInsertActionMode();
        break;
      case EntityType.Interface:
        setInsertInterfaceMode();
        break;
      case EntityType.Actor:
        setInsertActorMode();
        break;
      case EntityType.Spec:
        setInsertSpecMode();
        break;
      case EntityType.Thread:
        setInsertThreadMode();
        break;
      case EntityType.Capability:
        setInsertCapabilityMode();
        break;
    }
  }, [
    setInsertActionMode,
    setInsertActorMode,
    setInsertCapabilityMode,
    setInsertInterfaceMode,
    setInsertMomentMode,
    setInsertSpecMode,
    setInsertThreadMode,
    type,
  ]);

  const onDragStart = useCallback<DragEventHandler>(
    (event) => {
      event.dataTransfer.setData('application/reactflow', type);
      event.dataTransfer.effectAllowed = 'move';
      if (previewRef.current) event.dataTransfer.setDragImage(previewRef.current, 0, 0);
      setConstructToInsertUsingDrag(type);
    },
    [setConstructToInsertUsingDrag, type],
  );

  const onDragEnd = useCallback(() => {
    setConstructToInsertUsingDrag(null);
  }, [setConstructToInsertUsingDrag]);

  const icon = useMemo<IconName>(() => {
    switch (type) {
      case EntityType.Actor:
        return 'actor';
      case EntityType.Spec:
        return 'spec';
      case EntityType.Thread:
        return 'comment';
      case EntityType.Upload:
        return 'upload';
      default:
        return 'construct-rect-preview';
    }
  }, [type]);

  const color = useMemo(() => {
    const color = theme.palette.constructs[type]?.color;
    if (color) {
      return color;
    }

    return theme.palette.primary.main;
  }, [theme.palette.constructs, theme.palette.primary.main, type]);

  return (
    <SingleSourceModelToolbarButton
      draggable={type !== EntityType.Upload}
      onDragEnd={onDragEnd}
      Icon={<Icon ref={previewRef} name={icon} color={color} />}
      isActive={mode === `INSERT_${type.toUpperCase()}`}
      onClick={onClick}
      onDragStart={onDragStart}
      label={f({ id: type === EntityType.Thread ? EntityType.Comment : type })}
      tooltip={f({ id: `add-${type === EntityType.Thread ? EntityType.Comment.toLowerCase() : type.toLowerCase()}` })}
    />
  );
};
