import { useApolloClient, useMutation, useReactiveVar } from '@apollo/client';
import { showWorkspaceSettingsVar } from '../../state/state';
import { useIntl } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import { Box, Button, SxProps, TextField, Theme, Typography } from '@mui/material';
import { Modal } from '../modal/modal';
import { DELETE_WORKSPACE_MUTATION, RENAME_WORKSPACE_MUTATION } from '../../graphql/mutations';
import { RoutePaths } from '../../config/route-paths/route-paths';
import { useLocation, useNavigate } from 'react-router-dom';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { AppTypeEvent, useTrackEvents } from '../../hooks/use-track-events';
import { useSnackStack } from '../../wrappers/snack-stack-context';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';

const overflowSx: SxProps<Theme> = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

export const WorkspaceSettingsModal = () => {
  const open = useReactiveVar(showWorkspaceSettingsVar);
  const { workspace, setWorkspace } = useActiveWorkspace();
  const { organization, setOrganization } = useActiveOrganization();

  const [name, setName] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');

  const apolloClient = useApolloClient();
  const [deleteWorkspace] = useMutation(DELETE_WORKSPACE_MUTATION);
  const [renameWorkspace] = useMutation(RENAME_WORKSPACE_MUTATION);

  const { trackEvent } = useTrackEvents();

  const { addToast } = useSnackStack();

  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const onClose = useCallback(() => {
    setName(workspace?.name);
    setShowConfirm(false);
    setConfirmationMessage('');
    showWorkspaceSettingsVar(false);
  }, [workspace?.name]);

  const onNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const onConfirmationChange = useCallback((e) => {
    setConfirmationMessage(e.target.value);
  }, []);

  const isConfirmationValid = confirmationMessage === f({ id: 'delete' }).toLowerCase();

  const onSave = useCallback(async () => {
    const response = await renameWorkspace({ variables: { args: { newName: name, workspaceId: workspace?.id } } });
    if (!response.data) return;
    if (response.data.renameWorkspace.error) {
      addToast({ message: f({ id: 'workspace-rename-error' }), severity: 'error' });
    } else {
      addToast({
        message: f({ id: 'workspace-rename-success' }, { name: workspace?.name, newName: name }),
        severity: 'success',
      });
      trackEvent(AppTypeEvent.WorkspaceNameChanged, {
        workspaceId: workspace?.id,
        name: workspace?.name,
        newName: name,
      });
      await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
      const splitLocation = location.pathname.split('/');
      splitLocation[2] = encodeURIComponent(name);
      navigate(splitLocation.join('/'));
    }
    showWorkspaceSettingsVar(false);
  }, [
    renameWorkspace,
    name,
    workspace?.id,
    workspace?.name,
    addToast,
    f,
    trackEvent,
    apolloClient,
    location.pathname,
    navigate,
  ]);

  const onDelete = useCallback(async () => {
    if (!showConfirm) {
      setShowConfirm(true);
      return;
    }
    const response = await deleteWorkspace({
      variables: { args: { organizationId: organization?.id, workspaceId: workspace?.id } },
    });
    if (!response.data) return;
    if (response.data.deleteWorkspace.error) {
      addToast({ message: f({ id: 'workspace-deletion-error' }), severity: 'error' });
    } else {
      addToast({
        message: f({ id: 'workspace-deletion-success' }, { name: workspace?.name }),
        severity: 'success',
      });
      trackEvent(AppTypeEvent.WorkspaceDeleted, { workspaceId: workspace?.id, name: workspace?.name });
      await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
      setOrganization(null);
      setWorkspace(null);
      navigate(RoutePaths.ChooseOrganization);
    }
    showWorkspaceSettingsVar(false);
  }, [
    showConfirm,
    deleteWorkspace,
    organization?.id,
    workspace?.id,
    workspace?.name,
    addToast,
    f,
    trackEvent,
    apolloClient,
    setOrganization,
    setWorkspace,
    navigate,
  ]);

  useEffect(() => {
    setName(workspace?.name);
  }, [workspace?.name]);

  return (
    <Modal id="WorkspaceSettingsModal" isOpen={open} onClose={onClose}>
      <Box sx={{ mb: 4.5 }}>
        <Typography variant="h5" textAlign="center" sx={overflowSx}>
          {workspace?.name}
        </Typography>
        <Typography variant="h5" sx={{ textAlign: 'center' }}>
          {f({ id: 'settings' })}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <TextField
          inputProps={{ 'data-testid': 'WorkspaceSettingsModalNameTextFieldInput' }}
          value={name}
          onChange={onNameChange}
        />
        <Button variant="contained" disabled={!name || name === workspace?.name} onClick={onSave}>
          {f({ id: 'save' })}
        </Button>
        <Button variant="outlined" onClick={onClose}>
          {f({ id: 'cancel' })}
        </Button>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {showConfirm ? (
            <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="inputText">
                {f({ id: 'delete-workspace-description' }, { name: workspace?.name })}
              </Typography>
              <TextField
                inputProps={{ 'data-testid': 'WorkspaceSettingsModalDeleteTextFieldInput' }}
                fullWidth
                placeholder={f({ id: 'delete' }).toLowerCase()}
                onChange={onConfirmationChange}
              />
            </Box>
          ) : null}
          <Button
            data-testid="WorkspaceSettingsModalDeleteButton"
            variant="outlined"
            color="error"
            onClick={onDelete}
            fullWidth
            disabled={showConfirm ? !showConfirm || !isConfirmationValid : false}
          >
            <Typography sx={overflowSx}>{f({ id: 'delete-entity' }, { name: workspace?.name })}</Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
