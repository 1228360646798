import { useIntl } from 'react-intl';
import { Icon } from '../../../../../icons/icon';
import { Fragment, useMemo } from 'react';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { FiltersList, FiltersListProps } from '../../../filters-list/filters-list';
import { AttachmentType } from '@xspecs/single-source-model';
import { AttachmentIcon } from '../../../../single-source-model/canvas/attachments/attachment-icon/attachment-icon';
import { View } from '../filters-button-menu';

export type FiltersButtonMenuListProps = {
  onViewSelect: (view: View) => void;
  filtersListProps?: FiltersListProps;
};

export const FiltersButtonMenuList = (props: FiltersButtonMenuListProps) => {
  const { onViewSelect, filtersListProps } = props;

  const { formatMessage: f } = useIntl();

  const setLabelsView = useMemo(() => {
    return () => onViewSelect(View.Labels);
  }, [onViewSelect]);

  return (
    <List>
      <ListItem disablePadding>
        <ListItemButton sx={{ gap: 1, py: 0.5 }} onClick={setLabelsView}>
          <ListItemIcon sx={{ minWidth: 'fit-content' }}>
            <Icon name="label" />
          </ListItemIcon>
          <ListItemText primary={f({ id: 'labels' })} />
        </ListItemButton>
      </ListItem>
      {Object.values(AttachmentType).map((assetType) => (
        <ListItem disablePadding key={assetType}>
          <ListItemButton sx={{ gap: 1, py: 0.5 }} onClick={() => onViewSelect(View[assetType])}>
            <ListItemIcon sx={{ minWidth: 'fit-content' }}>
              <AttachmentIcon type={assetType} size={24} url={''} />
            </ListItemIcon>
            <ListItemText primary={assetType} />
          </ListItemButton>
        </ListItem>
      ))}
      {filtersListProps ? (
        <Fragment>
          <Divider sx={{ mb: 1.5 }} />
          {filtersListProps.savedFilters.length > 0 ? (
            <FiltersList {...filtersListProps} />
          ) : (
            <Typography variant="caption" textAlign="center">
              {f({ id: 'no-saved-filters' })}
            </Typography>
          )}
        </Fragment>
      ) : null}
    </List>
  );
};
