import { useApolloClient, useMutation } from '@apollo/client';
import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { RENAME_ORGANIZATION_MUTATION } from '../../../../graphql/mutations';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { isOrganizationNameValid } from '../../../../utils';
import { ORGANIZATIONS_QUERY } from '../../../../graphql/queries';
import { lightTheme } from '../../../../themes/light';
import { Button, Stack, TextField, Typography } from '@mui/material';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';

export type OrganizationSettingsModalGeneralNameProps = {
  onClose: () => void;
};

export const OrganizationSettingsModalGeneralName = (props: OrganizationSettingsModalGeneralNameProps) => {
  const { onClose } = props;

  const { addToast } = useSnackStack();

  const { organization } = useActiveOrganization();

  const [newName, setNewName] = useState(organization?.name || '');
  const [nameWarning, setNameWarning] = useState('');
  const [nameError, setNameError] = useState('');

  const isSaveDisabled = Boolean(nameWarning || nameError || !newName || newName === organization?.name);

  const apolloClient = useApolloClient();

  const [renameOrganization] = useMutation(RENAME_ORGANIZATION_MUTATION);

  const { formatMessage: f } = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  const onChange = useCallback<ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>>(
    (event) => {
      setNewName(event.target.value);
      if (isOrganizationNameValid(event.target.value)) {
        setNameWarning('');
      } else {
        setNameWarning(f({ id: 'organization-name-input-warning' }));
      }
    },
    [f],
  );

  const onSave = useCallback(async () => {
    const response = await renameOrganization({ variables: { args: { organizationId: organization?.id, newName } } });
    if (!response.data) return;
    if (response.data.renameOrganization.error) {
      setNameError(response.data.renameOrganization.error);
    } else {
      addToast({
        message: f({ id: 'organization-name-changed' }, { name: organization?.name, newName }),
        severity: 'success',
      });
      onClose();
      await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
      const pathPieces = location.pathname.split('/');
      pathPieces[1] = newName;
      const newPath = pathPieces.join('/');
      navigate(newPath);
    }
  }, [
    renameOrganization,
    organization?.id,
    organization?.name,
    newName,
    addToast,
    f,
    onClose,
    apolloClient,
    location.pathname,
    navigate,
  ]);

  const warningSx = {
    '.MuiFormHelperText-root': { mx: 0, color: 'warning.main' },
    '.MuiOutlinedInput-notchedOutline': { borderColor: `${lightTheme.palette.warning.main} !important` },
    '.MuiFormLabel-root': { color: `${lightTheme.palette.warning.main} !important` },
  };

  useEffect(() => {
    return () => {
      setNameWarning('');
      setNameError('');
      setNewName('');
    };
  }, []);

  return (
    <Stack px={1} width="100%" spacing={1.5}>
      <Typography variant="h6">{f({ id: 'organization-name' })}</Typography>
      <TextField
        fullWidth
        inputProps={{ 'data-testid': 'OrganizationSettingsModalGeneralTextFieldInput' }}
        variant="outlined"
        defaultValue={organization?.name}
        onChange={onChange}
        helperText={nameWarning}
        error={Boolean(nameError)}
        sx={{ ...(nameWarning ? warningSx : {}) }}
      />
      <Button
        data-testid="OrganizationSettingsModalGeneralSaveButton"
        fullWidth
        variant="contained"
        onClick={onSave}
        disabled={isSaveDisabled}
      >
        {f({ id: 'save' })}
      </Button>
      <Button data-testid="OrganizationSettingsModalGeneralCancelButton" fullWidth variant="outlined" onClick={onClose}>
        {f({ id: 'cancel' })}
      </Button>
    </Stack>
  );
};
