import { IStore } from '../../data/Store';
import { EntityRepository } from '../../data/EntityRepository';
import { EntityChanges } from '../../types';
import { AttachmentType, EntityType } from '../../entities/EntityType';
import { Attachment } from '../../entities/assets/Attachment';
import { TransientStore } from '../../data/Transient';
import { Query } from '../../entities/assets/Query';

export type EntityDetailsState = {
  id: string;
  type: EntityType | string;
  name: string;
  subType?: AttachmentType;
  asset?: {
    id: string;
    type: EntityType | string;
  };
  queryText?: string;
  iconUrl?: string;
};

export class EntityDetails {
  constructor(public readonly entityRepository: EntityRepository, public readonly store: IStore) {}

  private get entityId() {
    return TransientStore.provider.get('entityDetailView') ?? null;
  }

  private set entityId(value: string | null) {
    TransientStore.provider.set('entityDetailView', value);
  }

  close(entityId: string) {
    if (this.entityId !== entityId) return;
    this.entityId = null;
    this.clear();
  }

  update(changes: EntityChanges = { added: [], updated: [], deleted: [] }) {
    this.handleDeletions(changes);
    this.handleAdditions(changes);
    this.handleUpdates(changes);
  }

  private handleUpdates(changes: EntityChanges) {
    changes.updated.forEach((update) => {
      this.updateStore(update.entity.id);
    });
  }

  private handleAdditions(changes: EntityChanges) {
    changes.added.forEach((entity) => {
      this.updateStore(entity.id);
    });
  }

  private handleDeletions(changes: EntityChanges) {
    changes.deleted.forEach((entity) => {
      if (entity.id === this.entityId) {
        this.entityId = null;
        this.clear();
      }
    });
  }

  private updateStore(entityId: string) {
    if (this.entityId !== entityId) return;
    const entity = this.entityRepository.get(entityId)!;
    this.store.getState().setEntityDetails({
      id: entity.id,
      type: entity.type,
      name: entity.name,
      ...(entity instanceof Attachment ? { asset: entity.asset, subType: entity.subType } : {}),
      queryText: entity instanceof Query ? entity.queryText : undefined,
      iconUrl: entity.parent instanceof Attachment ? entity.parent.iconUrl : undefined,
    } satisfies EntityDetailsState);
  }

  public clear() {
    this.entityId = null;
    this.store.getState().setEntityDetails(null);
  }
}
