import {
  Box,
  Checkbox,
  createFilterOptions,
  List,
  ListItem,
  Stack,
  TextField,
  Typography,
  useAutocomplete,
  UseAutocompleteProps,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { AssetBase, AttachmentType } from '@xspecs/single-source-model';
import { Icon } from '../../icons/icon';
import { AssetsState } from '@xspecs/single-source-model/src/read-models/assets/Assets';

export type AssetsSelectorProps = {
  type: AttachmentType;
  assets: AssetsState;
  selectedAssets: AssetBase[];
  onSelectedAssetsChange: (assets: AssetBase[]) => void;
  placeholder?: string;
  disableFreeSolo?: boolean;
};

const filter = createFilterOptions<AssetBase>();

export const AssetsSelector = (props: AssetsSelectorProps) => {
  const { assets, onSelectedAssetsChange, selectedAssets = [], placeholder, disableFreeSolo = false, type } = props;

  const { formatMessage: f } = useIntl();

  const theme = useTheme();

  const autoCompleteProps = useMemo<UseAutocompleteProps<AssetBase, true, true, boolean>>(() => {
    return {
      open: true,
      multiple: true,
      freeSolo: !disableFreeSolo,
      value: selectedAssets,
      onChange: (event, value, reason, details) => {
        if (typeof value[0] === 'string') return;

        const newAssets = value as AssetBase[];
        onSelectedAssetsChange(newAssets);
      },
      options: assets[type],
      getOptionLabel: (option) => (typeof option === 'string' ? option : option.name),
      filterOptions: (options, params) => {
        const filtered = filter(options, params);
        if (disableFreeSolo) return filtered;
        return filtered;
      },
    };
  }, [disableFreeSolo, assets, onSelectedAssetsChange, selectedAssets, type]);

  const { getRootProps, getInputProps, getListboxProps, getOptionProps, groupedOptions } =
    useAutocomplete(autoCompleteProps);

  const inputProps = getInputProps();

  return (
    <Stack gap={1} alignItems="flex-start" {...getRootProps()} sx={{ py: 1.5 }}>
      <Stack sx={{ px: 2, width: '100%' }} gap={1.5}>
        <TextField
          fullWidth
          placeholder={placeholder ?? f({ id: 'search' })}
          slotProps={{
            htmlInput: inputProps,
            input: { startAdornment: <Icon name="search" /> },
          }}
          size="small"
        />
      </Stack>
      <List
        {...getListboxProps()}
        sx={{
          width: '100%',
          maxHeight: 294,
          height: 294,
          overflowY: 'scroll',
          '& li.Mui-focused': {
            backgroundColor: theme.palette.action.hover,
            color: theme.palette.text.primary,
            cursor: 'pointer',
          },
          '& li:active': {
            backgroundColor: theme.palette.action.selected,
            color: theme.palette.text.primary,
          },
        }}
      >
        <Box px={2}>
          {groupedOptions.map((option, index) => {
            const isOption = Boolean(option.id);
            return (
              <ListItem
                {...getOptionProps({ option, index })}
                key={`assetsSelector-option-${index}`}
                sx={{ p: 0, height: 38, px: isOption ? undefined : 2 }}
              >
                {isOption ? (
                  <Checkbox
                    size="small"
                    sx={{ px: 1 }}
                    checked={Boolean(selectedAssets.find((asset) => asset.id === option.id))}
                  />
                ) : null}
                <Typography variant="body2">{option.name}</Typography>
              </ListItem>
            );
          })}
        </Box>
      </List>
    </Stack>
  );
};
