import { FC } from 'react';
import { List } from '@mui/material';
import { PaletteIconItem } from '../icon-item/palette-icon-item';
import { PaletteAttachment } from '@xspecs/single-source-model';

type PaletteAttachablesListProps = {
  data: PaletteAttachment[];
};

export const PaletteAttachmentsList: FC<PaletteAttachablesListProps> = ({ data }) => {
  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
    >
      {data.map((item) => (
        <PaletteIconItem key={'PaletteAttachmentsList' + item.type} {...item} />
      ))}
    </List>
  );
};
