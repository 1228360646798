import { ArrowBack, MoreVert } from '@mui/icons-material';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { NavMenu } from '../nav-menu/nav-menu';
import { NavBarIconButton } from './nav-bar-icon-button';
import { ItemType, NavBarItemTypeIcon } from './nav-bar-item-type-icon';
import { NavRightItems } from './nav-right-items';
import { useViewportSize } from '@mantine/hooks';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { Icon } from '../../icons/icon';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export type NavBarProps = {
  isLoggedIn?: boolean;
  hasOrganizations?: boolean;
  title?: string;
  itemType?: ItemType;
  isItemView?: boolean;
  isPrimary?: boolean;
  hideLogo?: boolean;
  hideTextLogo?: boolean;
  hideWorkspaces?: boolean;
};

export const NavBar = (props: NavBarProps) => {
  const {
    isLoggedIn,
    hasOrganizations,
    hideWorkspaces,
    title,
    isItemView,
    itemType,
    isPrimary,
    hideLogo,
    hideTextLogo,
  } = props;

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const navigate = useNavigate();
  const location = useLocation();

  const { width } = useViewportSize();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const onBack = useCallback(() => {
    if (location.key === 'default') {
      navigate(
        getRoutePathFromParams(RoutePaths.Workspace, {
          organizationName: organization?.name,
          workspaceName: workspace?.name,
        }),
      );
    } else {
      navigate(-1);
    }
  }, [location.key, navigate, workspace?.name, organization?.name]);

  const rootSx = useMemo(
    () => ({
      backgroundColor: { xs: 'primary.main', md: isPrimary ? 'primary.main' : 'primary.contrastText' },
      display: 'grid',
      alignItems: 'center',
      gridTemplateColumns: 'auto 1fr auto',
      gridAutoFlow: 'column',
      height: 64,
      px: 1,
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    }),
    [isPrimary],
  );

  if (isItemView && itemType && !isMd) {
    return (
      <Box data-testid="nav-bar" sx={{ ...rootSx, gridTemplateColumns: '1fr auto', backgroundColor: 'primary.main' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'primary.contrastText' }}>
          <NavBarIconButton
            id="nav-back-button"
            sx={{ color: 'inherit' }}
            icon={<ArrowBack color="inherit" />}
            onClick={onBack}
          />
          <NavBarItemTypeIcon itemType={itemType} color={theme.palette.primary.contrastText} />
          <Typography
            data-testid="nav-title"
            sx={{
              marginLeft: '10px',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: `${width - 40 - 24 - 20}px`,
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box display={{ xs: 'none', md: 'block' }}>
          <NavBarIconButton
            id="nav-more-button"
            sx={{ color: 'primary.contrastText' }}
            icon={<MoreVert color="inherit" />}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box data-testid="nav-bar" sx={rootSx}>
      {isLoggedIn && hasOrganizations ? (
        <Box sx={{ marginLeft: { md: '20px' }, gridColumn: { md: '2' }, height: { md: '64px' } }}>
          <NavMenu hideWorkspaces={hideWorkspaces} />
        </Box>
      ) : null}
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: ['flex-start', 'flex-start', 'flex-start', 'center'],
          gap: 1,
        }}
      >
        {!hideLogo ? (
          <Icon
            data-testid="NarrativeLogo"
            name="narrative-filled"
            width={40}
            height={42}
            color={theme.palette.primary.main}
          />
        ) : null}
        {!hideTextLogo ? (
          <Icon
            data-testid="NarrativeTextLogo"
            name="narrative-text"
            width={86}
            height={32}
            color={isPrimary ? theme.palette.primary.contrastText : theme.palette.primary.main}
          />
        ) : null}
      </Stack>
      {isLoggedIn ? (
        <Box sx={{ height: '100%' }}>
          <NavRightItems {...props} />
        </Box>
      ) : null}
    </Box>
  );
};
