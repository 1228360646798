import { Box, Button, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { Fragment, useCallback } from 'react';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { AppsList } from './list/apps-list';
import { UploadAppModal } from './upload-app-modal/upload-app-modal';

export const Apps = () => {
  const { formatMessage: f } = useIntl();

  const { organization } = useActiveOrganization();
  const setShowUploadAppModal = useSingleSourceStore.use.setShowUploadAppModal();

  const onUploadApp = useCallback(() => {
    setShowUploadAppModal(true);
  }, [setShowUploadAppModal]);

  return (
    <Fragment>
      <Stack id="apps" width="100%" gap={1.5}>
        <Typography variant="h4" sx={{ mb: 1.5 }}>
          {f({ id: 'apps' })}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {f({ id: 'organization-apps-description' })}
        </Typography>
        <Box>
          <Button startIcon={<CloudUploadOutlinedIcon />} onClick={onUploadApp} size="small" variant="outlined">
            {f({ id: 'upload-app' })}
          </Button>
        </Box>
        <AppsList apps={organization.registeredApps} />
      </Stack>
      <UploadAppModal />
    </Fragment>
  );
};
