import { Alert, MenuItem, Select, SelectChangeEvent, SxProps, Theme } from '@mui/material';
import { Box } from '@mui/system';
import { useIntl } from 'react-intl';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useMutation } from '@apollo/client';
import { ContentSharingScope } from '../../../../gql/graphql';
import { useState } from 'react';
import { UPDATE_CONTENT_SHARING_SCOPE_MUTATION } from '../../../../graphql/mutations';
import { useSnackStack } from '../../../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../../../hooks/use-active-organization';

export const OrganizationSettingsModalContentSharing = () => {
  const { organization } = useActiveOrganization();
  const [updateContentSharingScope] = useMutation(UPDATE_CONTENT_SHARING_SCOPE_MUTATION);

  const [localScope, setLocalScope] = useState(organization.contentSharingScope);

  const { addToast } = useSnackStack();

  const { formatMessage: f } = useIntl();

  const info: Record<ContentSharingScope, string> = {
    [ContentSharingScope.Organization]: f({ id: 'organization-users-see-link-details' }),
    [ContentSharingScope.Anyone]: f({ id: 'anyone-can-see-link-details' }),
  };

  const options: Record<ContentSharingScope, string> = {
    [ContentSharingScope.Organization]: f({ id: 'content-sharing-scope-organization-option' }),
    [ContentSharingScope.Anyone]: f({ id: 'content-sharing-scope-anyone-option' }),
  };

  const handleRoleChange = async (e: SelectChangeEvent<ContentSharingScope>) => {
    const newScope = e.target.value as ContentSharingScope;

    const response = await updateContentSharingScope({
      variables: {
        args: {
          organizationId: organization.id,
          sharingScope: newScope,
        },
      },
    });
    if (!response.data) return;

    if (response.data.updateContentSharingScope.error) {
      addToast({ message: f({ id: 'organization-content-sharing-scope-update-error' }), severity: 'error' });
    } else {
      setLocalScope(newScope);
      addToast({
        message: f({ id: 'organization-content-sharing-scope-update-success' }, { scope: options[newScope] }),
        severity: 'success',
      });
    }
  };

  const alertSx: SxProps<Theme> = (theme) => ({
    padding: theme.spacing(0),
    marginTop: theme.spacing(1),
    color: theme.palette.info.dark,
    background: 'none',
    '& .MuiAlert-icon': {
      marginRight: theme.spacing(0.5),
      padding: theme.spacing(0),
    },
    '& .MuiAlert-message': {
      padding: theme.spacing(0),
    },
  });

  return (
    <Box>
      <Select
        value={localScope}
        onChange={handleRoleChange}
        size="small"
        sx={{ minWidth: '100px', width: '100%', mt: 1 }}
      >
        {Object.entries(options).map(([scopeValue, scopeLabel]) => (
          <MenuItem
            data-testid={`menuItem_scope-${scopeValue}`}
            key={scopeValue}
            value={ContentSharingScope[scopeValue]}
            sx={{ minWidth: '100px' }}
          >
            {scopeLabel}
          </MenuItem>
        ))}
      </Select>
      <Alert sx={alertSx} icon={<InfoOutlinedIcon fontSize="small" />} severity="info">
        {info[localScope]}
      </Alert>
    </Box>
  );
};
