import { List, ListSubheader, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { PaletteConstructListItem } from '../item/palette-construct-list-item';
import { PaletteConstructsRow } from '@xspecs/single-source-model';

type PaletteConstructListProps = {
  data: PaletteConstructsRow;
};

export const PaletteConstructList: FC<PaletteConstructListProps> = (props) => {
  const { data } = props;

  return (
    <Stack direction="row" gap={1} bgcolor="background.paper">
      {data.map((column) => (
        <List
          key={'PaletteConstructList' + column.caption}
          sx={{ p: 0 }}
          subheader={
            <ListSubheader>
              <Typography variant="overline">{column.caption}</Typography>
            </ListSubheader>
          }
        >
          {column.entities.map((item) => (
            <PaletteConstructListItem key={'PaletteConstructList' + item.type} {...item} />
          ))}
        </List>
      ))}
    </Stack>
  );
};
