import { Box, Slide } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback, useRef, useState } from 'react';
import { FiltersButtonMenuList } from './list/filters-button-menu-list';
import { LabelsSelector, LabelsSelectorProps } from '../../../labels/labels-selector/labels-selector';
import { FiltersListProps } from '../../filters-list/filters-list';
import { isValidAttachmentType } from '@xspecs/single-source-model';
import { AssetsSelector, AssetsSelectorProps } from '../../../assets-selector/assets-selector';

export enum View {
  Default = 'Default',
  Labels = 'Labels',
  Actor = 'Actor',
  Doc = 'Doc',
  Query = 'Query',
  Schema = 'Schema',
  Spec = 'Spec',
  Upload = 'Upload',
}

export type FiltersButtonMenuProps = {
  labelsSelectorProps: Omit<LabelsSelectorProps, 'hideManageLabels' | 'hideHeader' | 'placeholder'>;
  assetsSelectorProps: Omit<AssetsSelectorProps, 'hideManageLabels' | 'hideHeader' | 'placeholder' | 'type'>;
  filtersListProps?: FiltersListProps;
};

export const FiltersButtonMenu = (props: FiltersButtonMenuProps) => {
  const { labelsSelectorProps, filtersListProps, assetsSelectorProps } = props;

  const { formatMessage: f } = useIntl();

  const [view, setView] = useState<View>(View.Default);

  const onViewSelect = useCallback((view: View) => {
    setView(view);
  }, []);

  const rootRef = useRef<HTMLDivElement>(null);

  return (
    <Box ref={rootRef} sx={{ overflow: 'hidden' }}>
      {view === View.Labels ? (
        <Slide in direction="left" container={rootRef.current} mountOnEnter unmountOnExit>
          <Box>
            <LabelsSelector
              {...labelsSelectorProps}
              hideHeader
              hideManageLabels
              placeholder={f({ id: 'label-name' })}
            />
          </Box>
        </Slide>
      ) : null}
      {isValidAttachmentType(view) ? (
        <Slide in direction="left" container={rootRef.current} mountOnEnter unmountOnExit>
          <Box>
            <AssetsSelector {...assetsSelectorProps} type={view} />
          </Box>
        </Slide>
      ) : null}
      {view === View.Default ? (
        <FiltersButtonMenuList onViewSelect={onViewSelect} filtersListProps={filtersListProps} />
      ) : null}
    </Box>
  );
};
