import { IStore } from '../data/Store';
import { MessageBus } from '../commands/framework/MessageBus';
import { CommandConstructor, CommandBase, IParams } from '../commands/framework/CommandBase';
import { EventBase, EventConstructor } from '../commands/framework/EventBase';
import { logger } from '@xspecs/logger';

export type AppState = {
  [appName: string]: {
    [storeName: string]: object;
  };
};

type WorkerMessage =
  | { type: 'command'; commandClass: string; params: IParams }
  | { type: 'subscribe'; event: string }
  | { type: 'command-response'; event: string }
  | { type: 'update-store'; storeName: string; data: any };

type CommandResponseMessage = { type: 'command-response'; error?: string };
type EventMessage<T> = { type: 'event'; event: string; payload: T };

export class WorkerManager {
  private workers: Map<string, Worker>;

  constructor(private readonly messageBus: MessageBus, private readonly store: IStore) {
    this.workers = new Map();
  }

  addWorker({
    appName,
    workerScriptUrl,
    workerInstance,
  }: {
    appName: string;
    workerScriptUrl: string;
    workerInstance?: Worker;
  }) {
    if (this.workers.has(appName)) {
      throw new Error(`Worker for app ${appName} already exists.`);
    }

    this.store.getState().setAppState(appName, {});

    const worker = workerInstance || new Worker(new URL(workerScriptUrl), { type: 'module' });
    worker.onmessage = (event) => this.handleWorkerMessage(appName, event.data as WorkerMessage);

    this.workers.set(appName, worker);
  }

  private handleWorkerMessage(appName: string, message: WorkerMessage) {
    const worker = this.workers.get(appName);
    if (!worker) {
      logger.warn(`Worker for app ${appName} not found.`);
      return;
    }
    switch (message.type) {
      case 'command':
        this.handleWorkerCommand(worker, message.commandClass, message.params);
        break;

      case 'subscribe':
        this.handleWorkerEventSubscription(worker, message.event);
        break;

      case 'update-store':
        this.store.getState().setAppState(appName, {
          ...this.store.getState()[appName],
          ...message.data,
        } satisfies AppState);

        break;

      default:
        logger.warn(`Unknown message type received from app ${appName}:`, message);
        break;
    }
  }

  private handleWorkerCommand(worker: Worker, commandName: string, params: IParams) {
    const commandClass = this.messageBus.getCommand(commandName);
    if (!commandClass) {
      worker.postMessage({
        type: 'command-response',
        error: `Unknown command: ${commandName}`,
      } as CommandResponseMessage);
      return;
    }

    const response = this.messageBus.send(commandClass as CommandConstructor<CommandBase<IParams>>, params);

    if (response instanceof Error) {
      worker.postMessage({ type: 'command-response', error: response.message } as CommandResponseMessage);
    } else {
      worker.postMessage({ type: 'command-response' } as CommandResponseMessage);
    }
  }

  private handleWorkerEventSubscription(worker: Worker, eventName: string) {
    const eventClass = this.messageBus.getEvent(eventName);
    if (!eventClass) {
      return;
    }

    this.messageBus.subscribe([eventClass as EventConstructor<EventBase>], (event) => {
      worker.postMessage({
        type: 'event',
        event: eventName,
        payload: event,
      } as EventMessage<EventBase>);
    });
  }

  removeWorker({ appName }: { appName: string }): void {
    const worker = this.workers.get(appName);
    if (!worker) {
      throw new Error(`Worker for app ${appName} does not exist.`);
    }

    this.store.getState().setAppState(appName, {});

    worker.terminate();
    this.workers.delete(appName);
  }

  publishMessage({ appName, message }: { appName: string; message: unknown }): void {
    const worker = this.workers.get(appName);
    if (!worker) throw new Error(`Worker for app ${appName} does not exist.`);
    worker.postMessage(message);
  }
}
