import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import '@sentry/tracing';
import { isProd } from './utils';
import { AUTH0_LOCAL_TOKEN_KEY } from './config/auth0/Auth0Config';
import { CacheEntry, LocalStorageCache } from './auth';

const dsn = 'https://719f4e32b93a4421bd3d1cfe421cc65f@o4504670611636224.ingest.sentry.io/4504958040473600';
// const sentryDSN = location.origin.includes('app.xspecs.ai') ? dsn : null; // production only
const sentryDSN = location.origin.includes('localhost') ? undefined : dsn; // branches and prod
// const sentryDSN = dsn; // use for local testing of sentry

Sentry.init({
  dsn: sentryDSN,
  environment: isProd ? 'production' : 'development',
  replaysSessionSampleRate: 1,
  tracesSampleRate: isProd ? 0.5 : 10,
  replaysOnErrorSampleRate: 1.0,
  ignoreErrors: [
    'NotAllowedError: Clipboard read operation is not allowed.',
    "NotAllowedError: Failed to execute 'readText' on 'Clipboard': Read permission denied.",
    /LaunchDarklyFlagFetchError:.*/,
  ],
  integrations: [
    new Sentry.Replay({
      networkDetailAllowUrls: ["'https:\\/\\/.*.[xspecs|narrative].[io|ai|tech]'"],
    }),
    new CaptureConsole({
      // levels: ['log', 'info', 'warn', 'error', 'debug', 'assert', 'trace'],
      levels: ['error'],
    }),
  ],
});

const getUser = () => {
  const localStorageCache = new LocalStorageCache();
  const result = localStorageCache.get(AUTH0_LOCAL_TOKEN_KEY) as CacheEntry;

  if (!result || !result.decodedToken)
    return {
      id: 'anonymous',
      username: 'anonymous',
      email: 'anonymous',
    };

  const { user } = result.decodedToken;

  return {
    id: user.sub,
    username: user.name || user.nickname,
    email: user.email,
    // ip_address?: string;
    // segment?: string;
  };
};

const user = getUser();
Sentry.setUser(user);

Sentry.setTags({
  'user.name': user.username,
  'user.email': user.email,
});

Sentry.setContext('client', {
  // APP_URL: process.env.APP_URL,
  // BASE_STACK_NAME: process.env.BASE_STACK_NAME,
  // BRANCH_NAME: process.env.BRANCH_NAME,
  // DATABASE_NAME: process.env.DATABASE_NAME,
  // DATABASE_URL: process.env.DATABASE_URL,
  // DEBUG: process.env.DEBUG,
  // EVENT_STORE_URL: process.env.EVENT_STORE_URL,
  // FORMATTED_BRANCH_NAME: process.env.FORMATTED_BRANCH_NAME,
  // NODE_ENV: process.env.NODE_ENV,
  // STREAM_PREFIX: process.env.STREAM_PREFIX,
  // STREAM_VERSION: process.env.STREAM_VERSION,
});

if (user.id && user.id !== 'anonymous' && window.dataLayer) {
  window.dataLayer.push({
    event: 'userLogin',
    userId: user.id,
  });
}
