import { EntityParserFactory } from '../entities/constructs/EntityParserFactory';
import { EntityBase } from '../entities/EntityBase';
import { ConstructBase } from '../entities/constructs/ConstructBase';
import { AssetBase } from '../entities/assets/AssetBase';
import { EntityType } from '../entities/EntityType';
import { Attachment } from '../entities/assets/Attachment';

export enum ConstructShape {
  Rectangle = 'rectangle',
  Square = 'square',
}

export interface DynamicConstruct {
  type: EntityType | string;
  caption: string;
  backgroundColor: string;
  textColor: string;
  shape: ConstructShape;
}

export interface DynamicAttachment {
  type: EntityType | string;
  caption: string;
  icon: string;
  dataSource: string;
}

type UserDefinedEntity = DynamicConstruct | DynamicAttachment;

type EntityBaseConstructor = new (
  id: string,
  name: string,
  parent: EntityBase | undefined,
  position: { x: number; y: number },
  scopes: string[],
  attributes: any,
  width: number,
  height: number,
  isVisible: boolean,
  zIndex: number,
) => ConstructBase | AssetBase;

export class DynamicEntityRegistry {
  constructor() {}

  public static registerEntities(entities: { constructs: DynamicConstruct[]; assets: DynamicAttachment[] }) {
    if (entities.constructs) {
      entities.constructs.forEach((constructDef) => {
        const dynamicEntityClass = this.createEntityClass(constructDef, ConstructBase);
        EntityParserFactory.registerEntityType(constructDef.type, dynamicEntityClass);
      });
    }

    if (entities.assets) {
      entities.assets.forEach((attachmentDef) => {
        const dynamicEntityClass = this.createEntityClass(attachmentDef, AssetBase);
        EntityParserFactory.registerEntityType(attachmentDef.type, dynamicEntityClass);
      });
    }
  }

  public static deRegisterEntities(entityTypes: string[]) {
    entityTypes.forEach((entityType) => {
      EntityParserFactory.deRegisterEntityType(entityType);
    });
  }

  public static createDynamicFallbackEntity(data: any): EntityBaseConstructor {
    if (!data.__dynamic) {
      throw new Error('Unable to create dynamic entity as entity is not marked as dynamic', data);
    }
    if (data.__baseType === 'Construct') {
      return this.CreateConstructClass(data.type);
    } else if (data.__baseType === 'Asset') {
      return this.CreateAssetClass(data.type);
    }
    throw new Error('Unsupported entity for dynamic fallback');
  }

  private static createEntityClass(
    entity: UserDefinedEntity,
    baseClass: typeof ConstructBase | typeof AssetBase,
  ): EntityBaseConstructor {
    const entityType = entity.type;

    if (baseClass === ConstructBase) {
      return this.CreateConstructClass(entityType);
    } else if (baseClass === AssetBase) {
      Attachment.registerAssetIcon({
        assetType: entityType,
        url: (entity as DynamicAttachment).icon,
      });
      Attachment.registerDataSource({
        assetType: entityType,
        dataSource: (entity as DynamicAttachment).dataSource,
      });

      return this.CreateAssetClass(entityType);
    }
    throw new Error('Unsupported entity base class');
  }

  private static CreateAssetClass(entityType: EntityType | string) {
    return class extends AssetBase {
      public type = entityType;
      public __dynamic = true;
      public __baseType = 'Asset';

      constructor(
        id: string,
        name: string,
        parent: EntityBase | undefined,
        position: { x: number; y: number },
        scopes: string[],
        attributes: any,
        width: number,
        height: number,
        isVisible: boolean,
        zIndex: number,
      ) {
        super(id, name, parent, position, scopes, attributes, width, height, isVisible, zIndex);
      }

      static parse(data: any): AssetBase {
        return new this(
          data.id,
          data.name,
          undefined,
          data.position || { x: 0, y: 0 },
          data.scopes || [],
          data.attributes || {},
          data.width || 140,
          data.height || 80,
          data.isVisible || true,
          data.zIndex || 0,
        );
      }

      public isValid(): boolean {
        return true;
      }

      public serialize(reference: boolean = false): unknown {
        const baseData = super.serialize(reference);
        const serializedData = typeof baseData === 'object' && baseData !== null ? baseData : {};

        return {
          ...serializedData,
          __dynamic: this.__dynamic,
          __baseType: this.__baseType,
        };
      }
    };
  }

  private static CreateConstructClass(entityType: EntityType | string) {
    return class extends ConstructBase {
      public type = entityType;
      public __dynamic = true;
      public __baseType = 'Construct';

      constructor(
        id: string,
        name: string,
        parent: EntityBase | undefined,
        position: { x: number; y: number },
        scopes: string[],
        attributes: any,
        width: number,
        height: number,
        isVisible: boolean,
        zIndex: number,
      ) {
        super(id, name, parent, position, scopes, attributes, width, height, isVisible, zIndex);
      }

      static parse(data: any): ConstructBase {
        return new this(
          data.id,
          data.name,
          undefined,
          data.position || { x: 0, y: 0 },
          data.scopes || [],
          data.attributes || {},
          data.width || 140,
          data.height || 80,
          data.isVisible || true,
          data.zIndex || 0,
        );
      }

      public isValid(): boolean {
        return true;
      }

      public serialize(reference: boolean = false): unknown {
        const baseData = super.serialize(reference);
        const serializedData = typeof baseData === 'object' && baseData !== null ? baseData : {};
        return {
          ...serializedData,
          __dynamic: this.__dynamic,
          __baseType: this.__baseType,
        };
      }
    };
  }
}
