import { Label, EntityType } from '@xspecs/single-source-model';
import { ShowDetailsButton } from '../../../show-details-button/show-details-button';
import { FloatingMenuLabelsDetailsButton } from '../floating/menu/labels-details-button/floating-menu-labels-details-button';
import { CSSProperties, forwardRef, memo, RefObject, useCallback, useRef, useState } from 'react';
import { Box, IconButton, Stack, Tooltip, styled } from '@mui/material';
import {
  arrow,
  autoPlacement,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { useCopyEntityLink } from '../hooks/use-copy-entity-link';
import { CopyLinkButton } from '../../../copy-link-button/copy-link-button';
import { DeleteAssetButton } from '../../../delete-asset-button/delete-asset-button';
import { useSingleSourceStore } from '../../../../store/single-source-store/single-source-store';
import { useIntl } from 'react-intl';
import { OpenInNew } from '@mui/icons-material';
import { logger } from '@xspecs/logger';

type FloatingMenuProps = {
  id: string;
  styles: CSSProperties;
  arrowRef: RefObject<SVGSVGElement>;
  context: any;
  type: string;
  labels: Label[];
  onManageLabels: () => void;
  disableLabels?: boolean;
  name: string;
  subType?: string;
  url?: string;
  withCopy?: boolean;
  withDeleteAsset?: boolean;
};

const _FloatingMenu = forwardRef<HTMLDivElement, FloatingMenuProps>((props, ref) => {
  const {
    id,
    styles,
    arrowRef,
    context,
    type,
    labels,
    onManageLabels,
    disableLabels,
    name,
    subType,
    url,
    withCopy,
    withDeleteAsset,
    ...rest
  } = props;
  const { onCopyEntityLink } = useCopyEntityLink();
  const { formatMessage: f } = useIntl();
  const setShowDeleteAssetModal = useSingleSourceStore.use.setShowDeleteAssetModal();
  const setAssetToDelete = useSingleSourceStore.use.setAssetToDelete();

  const openInNewTab = useCallback(() => {
    if (!url) {
      logger.error('No URL provided to open in new tab');
      return;
    }
    window.open(url, '_blank');
  }, [url]);

  const copyEntityLink = useCallback(async () => {
    await onCopyEntityLink(id, type, name);
    onManageLabels();
  }, [onCopyEntityLink, id, type, name, onManageLabels]);

  const deleteAsset = useCallback(() => {
    setShowDeleteAssetModal(true);
    onManageLabels();
    setAssetToDelete(id);
  }, [setShowDeleteAssetModal, onManageLabels, setAssetToDelete, id]);

  return (
    <FloatingContent {...rest} ref={ref} sx={styles} className="nodrag">
      <FloatingArrow ref={arrowRef} context={context} fill="white" />
      <Stack gap={0.25} alignItems="center">
        {subType === EntityType.Upload ? (
          <Tooltip title={f({ id: 'open-in-new-tab' }, { type })} disableInteractive placement="right">
            <Box>
              <IconButton size="small" {...rest} onClick={openInNewTab}>
                <OpenInNew fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
        ) : (
          <ShowDetailsButton id={id} type={type} />
        )}
        {!disableLabels ? (
          <FloatingMenuLabelsDetailsButton entityId={id} selectedLabels={labels} onManageLabels={onManageLabels} />
        ) : null}
        {withCopy ? <CopyLinkButton onClick={copyEntityLink} /> : null}
        {withDeleteAsset ? <DeleteAssetButton onClick={deleteAsset} /> : null}
      </Stack>
    </FloatingContent>
  );
});
_FloatingMenu.displayName = 'FloatingMenu';
export const FloatingMenu = memo(_FloatingMenu);

const FloatingContent = styled(Box)(({ theme }) => ({
  padding: '2px 4px',
  borderRadius: 8,
  background: theme.palette.background.paper,
  filter:
    'drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12)) drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14)) drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.20))',
  zIndex: 'calc(infinity)',
}));

export const useFloatingMenu = () => {
  const [showFloating, setShowFloating] = useState(false);
  const arrowRef = useRef<SVGSVGElement>(null);
  const { floatingStyles, refs, context } = useFloating({
    placement: 'right',
    middleware: [offset(20), autoPlacement({ allowedPlacements: ['right'] }), arrow({ element: arrowRef.current })],
    open: showFloating,
    onOpenChange: setShowFloating,
  });
  const click = useClick(context, { keyboardHandlers: false, toggle: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);
  const onManageLabels = useCallback(() => setShowFloating(false), []);

  return {
    showFloating,
    floatingStyles,
    context,
    arrowRef,
    referenceProps: getReferenceProps(),
    floatingProps: getFloatingProps(),
    onManageLabels,
    referenceRef: refs.setReference,
    floatingRef: refs.setFloating,
  };
};
