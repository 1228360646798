import { Box, Button, IconButton, InputAdornment, OutlinedInput, Stack, SxProps, Theme } from '@mui/material';
import { AutoAwesomeOutlined, Search, SwapVert } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { ChangeEvent, useCallback, useRef, useState } from 'react';
import {
  arrow,
  autoPlacement,
  FloatingArrow,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import { Icon } from '../../../icons/icon';
import { lightTheme } from '../../../themes/light';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { AssetSort } from '@xspecs/single-source-model';

export const AssetControls = () => {
  const [showFloating, setShowFloating] = useState(false);

  const { formatMessage: f } = useIntl();

  const arrowRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const assetExplorer = useSingleSourceStore.use.assetExplorer();

  const { application } = useApplication();

  const { floatingStyles, refs, context } = useFloating({
    placement: 'bottom-end',
    middleware: [
      offset(10),
      autoPlacement({ allowedPlacements: ['bottom-end'] }),
      arrow({ element: arrowRef.current }),
    ],
    open: showFloating,
    onOpenChange: setShowFloating,
  });
  const click = useClick(context, { keyboardHandlers: false });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

  const setSortValue = useCallback(
    (sortValue: AssetSort) => {
      application?.model.sidebarInteractor.setAssetSort(sortValue);
      setShowFloating(false);
    },
    [application?.model.sidebarInteractor],
  );

  const setSearchValue = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      application?.model.sidebarInteractor.setAssetSearch(e.target.value);
    },
    [application?.model.sidebarInteractor],
  );

  return (
    <Box sx={controlsSx}>
      <OutlinedInput
        id="search"
        size="small"
        placeholder="Search"
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        }
        inputRef={inputRef}
        value={assetExplorer.searchQuery}
        onChange={setSearchValue}
      />
      <Box ref={refs.setReference} {...getReferenceProps()}>
        <IconButton size="small">
          <SwapVert color="action" />
        </IconButton>
      </Box>
      {showFloating ? (
        <Box ref={refs.setFloating} {...getFloatingProps()} style={floatingStyles} sx={floatingSx}>
          <FloatingArrow width={20} height={12} ref={arrowRef} context={context} fill="white" />
          <Stack gap={0.25} alignItems="start">
            <Button
              sx={{
                ...sortItemSx,
                backgroundColor: assetExplorer.sortQuery === AssetSort.Newest ? 'action.selected' : 'action',
              }}
              startIcon={<AutoAwesomeOutlined sx={{ width: '16px', color: 'action.active' }} />}
              onClick={() => setSortValue(AssetSort.Newest)}
              disableRipple
            >
              {f({ id: 'sort-newest' })}
            </Button>
            <Button
              sx={{
                ...sortItemSx,
                backgroundColor: assetExplorer.sortQuery === AssetSort.Ascending ? 'action.selected' : 'action',
              }}
              startIcon={<Icon name="sorting-a-z" width={16} height={16} color={lightTheme.palette.action.active} />}
              onClick={() => setSortValue(AssetSort.Ascending)}
              disableRipple
            >
              {f({ id: 'sort-a-z' })}
            </Button>
            <Button
              sx={{
                ...sortItemSx,
                backgroundColor: assetExplorer.sortQuery === AssetSort.Descending ? 'action.selected' : 'action',
              }}
              startIcon={<Icon name="sorting-z-a" width={16} height={16} color={lightTheme.palette.action.active} />}
              onClick={() => setSortValue(AssetSort.Descending)}
              disableRipple
            >
              {f({ id: 'sort-z-a' })}
            </Button>
          </Stack>
        </Box>
      ) : null}
    </Box>
  );
};

const controlsSx = { width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 1 };

const floatingSx: SxProps<Theme> = (theme) => ({
  padding: 0.5,
  borderRadius: 1,
  width: '200px',
  background: theme.palette.background.paper,
  boxShadow:
    '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
  zIndex: 'calc(infinity)',
});

const sortItemSx: SxProps<Theme> = {
  color: 'text.primary',
  width: '100%',
  textAlign: 'left',
  justifyContent: 'start',
  textTransform: 'none',
};
