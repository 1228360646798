import { Box, Divider, FormControlLabel, FormGroup, Stack, Switch } from '@mui/material';
import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { PaletteConstructList } from './construct-list/palette-construct-list';
import { PaletteAttachmentsList } from './attachables-list/palette-attachments-list';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { Palette as PaletteClass } from '@xspecs/single-source-model';
import { logger } from '@xspecs/logger';

export const Palette: FC = () => {
  const { formatMessage: f } = useIntl();
  const { application } = useApplication();

  const palette = useSingleSourceStore.use.palette();
  const showResolvedThreads = useSingleSourceStore.use.showResolvedThreads();

  const onChange = useCallback(
    (_, checked: boolean) => {
      application?.model.threadInteractor.toggleResolvedThreadsVisibility(checked);
    },
    [application?.model.threadInteractor],
  );

  if (!palette) {
    logger.error('Palette is not defined');
    return null;
  }

  return (
    <Stack
      sx={{
        boxShadow: '0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
        backgroundColor: 'background.paper',
        padding: 1,
        gap: 0.5,
      }}
      divider={<Divider />}
    >
      {palette.map((row, index) => {
        if (PaletteClass.isPaletteConstructsColumn(row)) {
          return <PaletteConstructList key={'PaletteConstructList' + index} data={row} />;
        }

        if (PaletteClass.isPaletteAttachmentRow(row)) {
          return <PaletteAttachmentsList key={'PaletteAttachmentsList' + index} data={row} />;
        }
      })}
      <Box p={1}>
        <FormGroup>
          <FormControlLabel
            label={f({ id: 'show-resolved-comments' })}
            control={<Switch onChange={onChange} checked={showResolvedThreads} />}
          />
        </FormGroup>
      </Box>
    </Stack>
  );
};
