import { ChangeEvent, useCallback, useState } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useMutation } from '@apollo/client';
import { DELETE_ORGANIZATION_MUTATION } from '../../../graphql/mutations';
import { ORGANIZATIONS_QUERY } from '../../../graphql/queries';
import { apolloClient } from '../../../config/apollo/ApolloClient';
import { RoutePaths } from '../../../config/route-paths/route-paths';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';

type OrganizationSettingsModalAdministrationProps = {
  onClose: () => void;
};

export const OrganizationSettingsModalAdministration = ({ onClose }: OrganizationSettingsModalAdministrationProps) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmationValue, setConfirmationValue] = useState('');
  const [deleteOrganization] = useMutation(DELETE_ORGANIZATION_MUTATION);
  const { formatMessage: f } = useIntl();
  const { organization, setOrganization } = useActiveOrganization();
  const { setWorkspace } = useActiveWorkspace();
  const navigate = useNavigate();

  const { addToast } = useSnackStack();

  const onConfirmationChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setConfirmationValue(e.target.value);
  }, []);

  const onDelete = useCallback(async () => {
    if (!showConfirm) {
      setShowConfirm(true);
      return;
    }

    const response = await deleteOrganization({
      variables: { args: { organizationId: organization?.id } },
    });
    if (!response.data) return;

    if (response.data.deleteOrganization.error) {
      addToast({ message: f({ id: 'organization-deletion-error' }), severity: 'error' });
    } else {
      addToast({
        message: f({ id: 'organization-deletion-success' }, { name: organization?.name }),
        severity: 'success',
      });
      await apolloClient.refetchQueries({ include: [ORGANIZATIONS_QUERY] });
      setOrganization(null);
      setWorkspace(null);
      navigate(RoutePaths.ChooseOrganization);
    }
    onClose();
  }, [
    showConfirm,
    deleteOrganization,
    organization?.id,
    organization?.name,
    onClose,
    addToast,
    f,
    setOrganization,
    setWorkspace,
    navigate,
  ]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {showConfirm ? (
        <Box sx={{ gap: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography variant="inputText" data-testid="OrganizationSettingsAdminModalDeleteTextFieldLabel">
            {f({ id: 'delete-organization-description' }, { name: organization?.name })}
          </Typography>
          <TextField
            inputProps={{ 'data-testid': 'OrganizationSettingsAdminModalDeleteTextFieldInput' }}
            fullWidth
            placeholder="delete"
            value={confirmationValue}
            onChange={onConfirmationChange}
          />
        </Box>
      ) : null}
      <Button
        data-testid="OrganizationSettingsAdminModalDeleteButton"
        variant="outlined"
        color="error"
        onClick={onDelete}
        fullWidth
        disabled={showConfirm && confirmationValue !== 'delete'}
      >
        {`Delete ${organization?.name}`}
      </Button>
    </Box>
  );
};
