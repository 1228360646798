import { FiltersButton } from '../../filters/filters-button/filters-button';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useMemo } from 'react';
import { FiltersButtonMenuProps } from '../../filters/filters-button/menu/filters-button-menu';
import { useApplication } from '../../../wrappers/application-context/application-context';
import { AssetBase, Label } from '@xspecs/single-source-model';
import { SxProps, Theme } from '@mui/material';

type NavMenuLabelsProps = {
  buttonSx?: SxProps<Theme>;
};

export const NavMenuLabels = (props: NavMenuLabelsProps) => {
  const { buttonSx } = props;

  const { application } = useApplication();

  const labels = useSingleSourceStore.use.labels();
  const assets = useSingleSourceStore.use.assets();
  const unsavedFilter = useSingleSourceStore.use.unsavedFilter();
  const savedFilters = useSingleSourceStore.use.savedFilters();

  const filtersButtonMenuProps = useMemo<FiltersButtonMenuProps>(
    () =>
      ({
        labelsSelectorProps: {
          labels,
          selectedLabels: (unsavedFilter?.criteria.filter((c) => c instanceof Label) as Label[]) ?? [],
          onSelectedLabelsChange: (labels) => {
            application?.model.filtersInteractor.applyFilter(labels);
          },
          createAndSelectLabel: () => {},
          disableFreeSolo: true,
        },
        assetsSelectorProps: {
          assets,
          selectedAssets: (unsavedFilter?.criteria.filter((c) => c instanceof AssetBase) as AssetBase[]) ?? [],
          onSelectedAssetsChange: (assets) => {
            application?.model.filtersInteractor.applyFilter(assets);
          },
          disableFreeSolo: true,
        },
        filtersListProps: {
          savedFilters,
          onSavedFilterSelect: (filter) => {
            application?.model.filtersInteractor.applySavedFilter(filter.id);
          },
        },
      } satisfies FiltersButtonMenuProps),
    [application, savedFilters, labels, unsavedFilter?.criteria, assets],
  );

  return <FiltersButton filtersButtonMenuProps={filtersButtonMenuProps} buttonSx={buttonSx} />;
};
