import { EntityBase } from '../EntityBase';
import { z } from 'zod';
import { EntityType } from '../EntityType';
import { ConstructBase } from '../constructs/ConstructBase';
import { Z_INDEXES } from '../../ZIndexes';

enum HandleLocation {
  Top = 'Top',
  Right = 'Right',
  Bottom = 'Bottom',
  Left = 'Left',
  Float = 'Float',
  Relative = 'Relative',
}

type EdgeData = z.infer<typeof Edge.schema>;

export class Edge extends EntityBase {
  constructor(data: EdgeData, omitReferencesParsing: boolean = false) {
    let parsedData: EdgeData;
    if (omitReferencesParsing) {
      parsedData = Edge.schema.omit({ source: true, target: true }).parse(data);
    } else {
      parsedData = Edge.schema.parse(data);
    }
    super(
      parsedData.id,
      parsedData.name,
      parsedData.parent,
      parsedData.position,
      parsedData.scopes,
      parsedData.attributes,
      parsedData.width,
      parsedData.height,
      parsedData.isVisible,
      parsedData.zIndex,
    );
    this.source = data.source;
    this.target = data.target;
    this.sourceHandleLocation = parsedData.sourceHandleLocation ?? HandleLocation.Float;
    this.targetHandleLocation = parsedData.targetHandleLocation ?? HandleLocation.Float;
  }

  //static version = '1.0.0';
  //static version = '1.0.1'; // Renames Tags to Labels;
  // static version = '1.0.2'; // Removes Edges with an Action Source;
  static version = '1.0.3'; // Updates z index of edges

  static schema = EntityBase.abstractBaseSchema.extend({
    type: z.nativeEnum(EntityType).readonly().default(EntityType.Edge),
    sourceHandleLocation: z.nativeEnum(HandleLocation).optional(),
    targetHandleLocation: z.nativeEnum(HandleLocation).optional(),
    position: z
      .object({
        x: z.number(),
        y: z.number(),
      })
      .optional(),
    name: z.string().optional().default(''),
    source: z.lazy(() => ConstructBase.baseSchema),
    target: z.lazy(() => ConstructBase.baseSchema),
    scopes: z.array(z.string()).optional().default([]),
    zIndex: z.number().default(Z_INDEXES.Edge),
  });

  source: ConstructBase;
  sourceHandleLocation: HandleLocation = HandleLocation.Float;
  target: ConstructBase;
  targetHandleLocation: HandleLocation = HandleLocation.Float;

  static references = ['source', 'target'];

  get type(): EntityType {
    return EntityType.Edge;
  }

  static parse(data: EdgeData, omitReferences: boolean = false): Edge {
    let parsedData: Edge;
    if (omitReferences) {
      parsedData = Edge.schema.omit({ source: true, target: true }).parse(data);
    } else {
      parsedData = Edge.schema.parse(data);
    }
    return new Edge(parsedData, omitReferences);
  }

  isValid(): boolean {
    return Edge.schema.safeParse(this).success;
  }

  serialize(reference: boolean = false): unknown {
    if (reference) return super.serialize(reference);
    return {
      ...(super.serialize() as any),
      source: this.source?.serialize(true),
      target: this.target?.serialize(true),
    };
  }
}
