import { EntityRepository } from '../../data/EntityRepository';
import { ExplorerItem, ExplorerResult } from './ExplorerItem';
import { Errors } from '@xspecs/errors';
import { EntityChanges } from '../../types';
import { IStore } from '../../data/Store';
import { EntityBase } from '../../entities/EntityBase';
import { EntityType } from '../../entities/EntityType';
import { Capability } from '../../entities/scripts/Capability';
import { NarrativeScript } from '../../entities/scripts/NarrativeScript';
import { Attachment } from '../../entities/assets/Attachment';

export class Explorer {
  constructor(
    private readonly entityRepository: EntityRepository,
    private readonly clientId: string,
    public readonly store: IStore, // TODO: make this private
  ) {}

  private _result: ExplorerResult = { items: [] };
  private _searchTem: string;

  public get result(): ExplorerResult {
    return this._result;
  }

  public hasChanges(changes: EntityChanges = { added: [], updated: [], deleted: [] }): boolean {
    const propertiesToCheck = [
      'selectedBy',
      'name',
      'parent.$ref',
      'moment.$ref',
      'asset.$ref',
      'backend',
      'frontend',
      'attachments',
    ];

    function hasChanges(): boolean {
      if (changes.added.length === 0 && changes.updated.length > 0 && changes.deleted.length === 0) {
        return changes.updated.some((entry) => {
          return entry.modifiedProperties.some((prop) => propertiesToCheck.includes(prop));
        });
      }
      return true;
    }
    return hasChanges();
  }

  private loadInternal(changes: EntityChanges): Explorer {
    if (this._searchTem && this._searchTem.length > 0) {
      this.search(this._searchTem);
      return this;
    }
    if (this.hasChanges(changes)) {
      this.loadFromRepository();
    }
    return this;
  }

  public load(changes: EntityChanges): Explorer {
    const result = this.loadInternal(changes).result;
    this.store.getState().setExplorerResult(result);
    return this.loadInternal(changes);
  }

  public setSearchTem(term: string): void {
    this._searchTem = term;
  }

  public search(term: string): Explorer {
    this._searchTem = term;
    if (!term) {
      this.loadFromRepository();
      return this;
    }
    const allItems = this.loadFromRepository().result.items;
    let foundAny = false;
    const resultSet = new Set<ExplorerItem>();
    allItems.forEach((item) => {
      if (item.name.toLowerCase().includes(term.toLowerCase())) {
        foundAny = true;
        item.isHighlighted = true;
        let currentItem = item;
        while (currentItem) {
          resultSet.add(currentItem);
          currentItem = allItems.find((i) => i.id === currentItem.parentId)!;
        }
      }
    });
    if (!foundAny) {
      this._result = { items: [], error: Errors.NoResultsFound };
      return this;
    }
    this._result = { items: allItems.filter((item) => resultSet.has(item)) };
    return this;
  }

  private createExplorerItem(entity: EntityBase, parentId: string): ExplorerItem {
    if (this._result.items.find((item) => item.id === entity.id)) {
      return null!;
    }

    let name = entity.name;
    if (entity.type === EntityType.Attachment) {
      const attachment = entity as Attachment;
      if (attachment.asset) {
        name = attachment.asset.name;
      }
    }
    return new ExplorerItem(
      entity,
      entity.id,
      name,
      entity.type as EntityType,
      parentId,
      false,
      this.isCurrentUserSelected(entity),
    );
  }

  private loadFromRepository(): Explorer {
    this._result = { items: [] };
    this.mutateExplorerItems(this.entityRepository.list());
    return this;
  }

  private mutateExplorerItems(entities: EntityBase[]) {
    // entities
    //   .filter((p) => p instanceof Script)
    //   .sort((a, b) => (a.name > b.name ? 1 : -1))
    //   .forEach((narrative: Script) => {
    //     narrative.getTreeList().forEach(({ entity, parentId }) => {
    //       const item = this.createExplorerItem(entity, parentId);
    //       if (item) {
    //         this._result.items.push(item);
    //       }
    //     });
    //   });
    const filteredEntities = entities.filter(
      (entity) =>
        // entity.type !== EntityType.Frame &&
        // entity.type !== EntityType.Comment &&
        // entity.type !== EntityType.Thread &&
        // entity.type !== EntityType.Lane &&
        // !(entity instanceof AssetBase) &&
        // !(entity instanceof Edge) &&
        entity instanceof Capability || entity instanceof NarrativeScript || entity instanceof Attachment,
      //!(entity instanceof Script), // Explicitly exclude Narratives processed in the first pass
    );
    //filteredEntities.sort((a, b) => a.name.localeCompare(b.name)); // Sort only once for the second pass
    filteredEntities.forEach((entity) => {
      const item = this.createExplorerItem(entity, entity.parent?.id as string);
      if (item) {
        this._result.items.push(item);
      }
    });
  }

  private isCurrentUserSelected(entity: EntityBase): boolean {
    return entity?.selectedBy?.id === this.clientId;
  }
}
