import { SingleSourceModel } from '../SingleSourceModel';
import { DeleteAssetCommand } from '../commands/entities/DeleteAssetCommand';
import { SetAssetExplorerSearchCommand } from '../commands/explorer/SetAssetExplorerSearchCommand';
import { AssetSort } from '../read-models/asset-explorer/AssetExplorerItem';
import { SetAssetExplorerSortCommand } from '../commands/explorer/SetAssetExplorerSortCommand';

export class SidebarInteractor {
  constructor(private readonly model: SingleSourceModel) {}

  deleteAsset(id: string) {
    this.model.messageBus.send(DeleteAssetCommand, { entityId: id });
  }

  setAssetSearch(search: string) {
    this.model.messageBus.send(SetAssetExplorerSearchCommand, { search });
  }

  setAssetSort(sort: AssetSort) {
    this.model.messageBus.send(SetAssetExplorerSortCommand, { sort });
  }
}
