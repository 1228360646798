import { Button, Stack, Typography } from '@mui/material';
import { ArrowBackOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useIntl } from 'react-intl';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../../hooks/use-active-organization';

export const SettingsNav = () => {
  const { formatMessage: f } = useIntl();

  const { organization } = useActiveOrganization();
  const { workspace } = useActiveWorkspace();

  const navigate = useNavigate();
  const location = useLocation();

  const sections = useMemo(
    () => [
      { label: f({ id: 'plans' }), id: '#plans', active: location.hash === '#plans' },
      { label: f({ id: 'billing' }), id: '#billing', active: location.hash === '#billing' },
      { label: f({ id: 'apps' }), id: `#apps`, active: location.hash === '#apps' },
    ],
    [f, location.hash],
  );

  const goBack = useCallback(() => {
    if (location.key === 'default') {
      navigate(
        getRoutePathFromParams(RoutePaths.Workspace, {
          organizationName: organization?.name,
          workspaceName: workspace?.name,
        }),
      );
    } else {
      navigate(-1);
    }
  }, [location.key, navigate, organization?.name, workspace?.name]);

  const activeSession = useMemo(() => sections.find(({ active }) => active), [sections]);

  useEffect(() => {
    if (activeSession) {
      setTimeout(() => {
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 1000);
    } else {
      navigate(
        getRoutePathFromParams(RoutePaths.Settings, {
          organizationName: organization?.name,
        }) + '#plans',
        { replace: true },
      );
    }
  }, [activeSession, location.hash, navigate, organization?.name]);

  return (
    <Stack width={300} alignItems="flex-start" gap={2}>
      <Button startIcon={<ArrowBackOutlined />} onClick={goBack} fullWidth sx={buttonSx}>
        {f({ id: 'back-to-workspace' })}
      </Button>
      <Stack width="100%" gap={1.5} px={4}>
        {sections.map(({ label, id, active }) => (
          <Typography key={'settings-nav-' + id} color={active ? 'primary.main' : undefined}>
            <a href={id}>{label}</a>
          </Typography>
        ))}
      </Stack>
    </Stack>
  );
};

const buttonSx = {
  justifyItems: 'flex-start',
};
