import { useNavigate, useOutletContext } from 'react-router-dom';
import { useSingleSourceModel } from '../../../hooks/use-single-source-model';
import { getRoutePathFromParams, RoutePaths } from '../../../config/route-paths/route-paths';
import { useCallback, useEffect } from 'react';
import { EntityType } from '@xspecs/single-source-model';
import { Loading } from '../../../components/loading/loading';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { Box, IconButton, Stack } from '@mui/material';
import { Close } from '@mui/icons-material';
import { EntityDetailsMenu } from '../../../components/single-source-model/entity-details/entity-details-menu';
import { useActiveWorkspace } from '../../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../../hooks/use-active-organization';
import { EntityDetails } from '../../../components/single-source-model/entity-details/entity-details';

export const WorkspaceEntityRoute = () => {
  const { entityId } = useOutletContext<{ entityId: string }>();

  const { workspace } = useActiveWorkspace();
  const { organization } = useActiveOrganization();
  const isLoaded = useSingleSourceStore.use.isLoaded();
  const entityDetails = useSingleSourceStore.use.entityDetails();
  const setConstructToPanTo = useSingleSourceStore.use.setConstructToPanTo();

  const model = useSingleSourceModel();
  const navigate = useNavigate();

  const isAttachmentWithoutAsset =
    entityDetails && entityDetails.type === EntityType.Attachment && !entityDetails.asset;

  const onClose = useCallback(() => {
    model?.entityDetailsInteractor.closeEntity(entityId);
    navigate(
      getRoutePathFromParams(RoutePaths.Workspace, {
        organizationName: organization.name,
        workspaceName: workspace.name,
      }),
    );
  }, [entityId, model?.entityDetailsInteractor, navigate, organization.name, workspace.name]);

  useEffect(() => {
    if (!isLoaded) return;
    setTimeout(() => {
      const result = model?.entityDetailsInteractor.viewEntity(entityId);
      if (result instanceof Error) onClose();
    }, 0);
  }, [
    entityId,
    isLoaded,
    model?.entityDetailsInteractor,
    model?.messageBus,
    navigate,
    onClose,
    organization.name,
    workspace.name,
  ]);

  useEffect(() => {
    if (!isLoaded) return;
    if (entityDetails && workspace && organization) {
      if (
        entityDetails.type === EntityType.Thread ||
        (entityDetails.type === EntityType.Attachment && !entityDetails.asset)
      ) {
        onClose();
        return;
      }
      setTimeout(() => {
        setConstructToPanTo(entityId);
        if (entityDetails.type === EntityType.Thread) {
          onClose();
        }
      }, 10);
    }
  }, [entityDetails, entityId, isLoaded, navigate, onClose, organization, setConstructToPanTo, workspace]);

  const setSelectedGraph = useSingleSourceStore.use.setSelectedGraph();
  const setVariantProposal = useSingleSourceStore.use.setVariantProposal();
  const setGraphDetails = useSingleSourceStore.use.setGraphDetails();
  const setLoadingVariantsProposal = useSingleSourceStore.use.setLoadingVariantsProposal();

  useEffect(() => {
    return () => {
      setVariantProposal('');
      setGraphDetails(null);
      setLoadingVariantsProposal(false);
    };
  }, [entityId, setGraphDetails, setLoadingVariantsProposal, setSelectedGraph, setVariantProposal]);

  if (!isLoaded) {
    return (
      <Loading
        rootProps={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        circularProgressProps={{ size: 18 }}
      />
    );
  }

  if (!entityDetails) {
    return null;
  }

  if (isAttachmentWithoutAsset) {
    onClose();
    return null;
  }

  return (
    <Stack height="100%" p={1}>
      <Stack direction="row" justifyContent="end">
        <EntityDetailsMenu id={entityDetails.id} type={entityDetails.type} name={entityDetails.name} />
        <IconButton size="small" sx={{ alignSelf: 'flex-end' }} onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Stack>
      <Box flexGrow={1} minHeight={0}>
        {/*<CustomEntityDetails />*/}
        <EntityDetails key={entityDetails.id} entity={entityDetails} />
      </Box>
    </Stack>
  );
};
