import { forwardRef, useMemo } from 'react';
import { Actor } from './components/actor';
import { Query } from './components/query';
import { Spec } from './components/spec';
import { KeyValuePairs } from './components/key-value-pairs';
import { Narrative } from './components/narrative';
import { AddFrame } from './components/add-frame';
import { RemoveFrame } from './components/remove-frame';
import { Doc } from './components/doc';
import { NarrativeText } from './components/narrative-text';
import { ConstructPreview } from './components/construct-preview';
import { Maximize } from './components/maximize';
import { Minimize } from './components/minimize';
import { NarrativeFilled } from './components/narrative-filled';
import { Script } from './components/script';
import { ConstructRectanglePreview } from './components/construct-rectangle-preview';
import { ShowDetails } from './components/show-details';
import { Comment } from './components/comment';
import { AttachFileRounded, LabelOutlined, SearchOutlined, CloseOutlined } from '@mui/icons-material';
import { ExpandScript } from './components/expand-script';
import { CollapseScript } from './components/collapse-script';
import { Subscript } from './components/subscript';
import { ExpandSubscript } from './components/expand-subscript';
import { CollapseSubscript } from './components/collapse-subscript';
import { Expand } from './components/expand';
import { ExpandImage } from './components/expand-image';
import { CopyGqlLink } from './components/copy-gql-link';
import { Jira } from './components/jira';
import { Confluence } from './components/confluence';
import { Slack } from './components/slack';
import { Linear } from './components/linear';
import { Notion } from './components/notion';
import { Miro } from './components/miro';
import { GoogleSheets } from './components/google-sheets';
import { GoogleDocs } from './components/google-docs';
import { Figma } from './components/figma';
import { SortingAZ } from './components/sorting-a-z';
import { SortingZA } from './components/sorting-z-a';
import { ShowSidebar } from './components/show-sidebar';
import { HideSidebar } from './components/hide-sidebar';
import { AssetExplorer } from './components/asset-explorer';

export type IconName = keyof typeof iconsMap;

export type IconProps = {
  name: IconName;
  height?: number;
  width?: number;
  color?: string;
  'data-testid'?: string;
};

const iconsWithPadding: IconName[] = ['key-value-pairs', 'confluence', 'jira', 'slack', 'notion', 'asset-explorer'];

export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { name, height = 24, width = 24, color = 'currentColor' } = props;

  const IconComponent = iconsMap[name];

  const aspectRatio = width / height;

  const requiresPadding = useMemo(() => iconsWithPadding.includes(name), [name]);

  return (
    <svg
      data-testid={props['data-testid']}
      ref={ref}
      width={width}
      height={height}
      viewBox={Math.floor(aspectRatio) === 1 ? `0 0 24 24` : `0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        paddingLeft: requiresPadding ? 3 : 0,
        paddingTop: requiresPadding ? 3 : 0,
      }}
    >
      {/* @ts-ignore */}
      <IconComponent color={color} />
    </svg>
  );
});

Icon.displayName = 'Icon';

const iconsMap = {
  actor: Actor,
  spec: Spec,
  'key-value-pairs': KeyValuePairs,
  query: Query,
  narrative: Narrative,
  'add-frame': AddFrame,
  'remove-frame': RemoveFrame,
  doc: Doc,
  box: ConstructPreview, //TODO: rename this icon to construct-preview
  'narrative-text': NarrativeText,
  'narrative-filled': NarrativeFilled,
  maximize: Maximize,
  minimize: Minimize,
  script: Script,
  'construct-rect-preview': ConstructRectanglePreview,
  'show-details': ShowDetails,
  comment: Comment,
  upload: AttachFileRounded,
  'expand-script': ExpandScript,
  'collapse-script': CollapseScript,
  'expand-subscript': ExpandSubscript,
  'collapse-subscript': CollapseSubscript,
  subscript: Subscript,
  label: LabelOutlined,
  search: SearchOutlined,
  close: CloseOutlined,
  expand: Expand,
  'expand-image': ExpandImage,
  'copy-gql-link': CopyGqlLink,
  jira: Jira,
  confluence: Confluence,
  slack: Slack,
  linear: Linear,
  notion: Notion,
  miro: Miro,
  'google-sheets': GoogleSheets,
  'google-docs': GoogleDocs,
  figma: Figma,
  'sorting-a-z': SortingAZ,
  'sorting-z-a': SortingZA,
  'show-sidebar': ShowSidebar,
  'hide-sidebar': HideSidebar,
  'asset-explorer': AssetExplorer,
} as const;

export type IconComponentProps = {
  color: string;
};
