import { Stack, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { QueryView } from './query-view';
import { SeedData } from './seed-data';
import { useSingleSourceStore } from '../../../store/single-source-store/single-source-store';
import { useIntl } from 'react-intl';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useSnackStack } from '../../../wrappers/snack-stack-context';
import { getOperationName, runQuery } from './instant-mock-api';
import { logger } from '@xspecs/logger';
import { ActualQueryProps } from './actual-query';

type QueryDetailsProps = {
  id: string;
  noAnnotateDesign?: boolean;
  queryText: string;
} & Pick<ActualQueryProps, 'getViewport'>;

export const QueryDetails = (props: QueryDetailsProps) => {
  const { id, noAnnotateDesign, queryText, getViewport } = props;

  const { formatMessage: f } = useIntl();
  const { addToast } = useSnackStack();
  const seedData = useSingleSourceStore.use.seedData();
  const instantMockUrl = useSingleSourceStore.use.instantMockUrl();
  const selectedGraph = useSingleSourceStore.use.selectedGraph();
  const variantProposal = useSingleSourceStore.use.variantProposal();
  const annotator = useSingleSourceStore.use.annotator();

  const [value, setValue] = useState(0);
  const [seedDataValue, setSeedDataValue] = useState(JSON.stringify(seedData, null, 2));
  const [loading, setLoading] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onSeedData = useCallback(async () => {
    const [graphId, variantName] = variantProposal.split('@');
    try {
      setLoading(true);
      await axios.post(
        `${instantMockUrl}/seeds`,
        {
          seedResponse: JSON.parse(seedDataValue),
          operationName: getOperationName(queryText),
          operationMatchArguments: {},
          seedGroupId: 1,
          graphId,
          variantName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'seed-group': 'default',
          },
        },
      );
      await runQuery(queryText, variantProposal);
    } catch (error) {
      logger.error('Error registering seed:', error);
      addToast({ message: 'Error registering seed', severity: 'error' });
    } finally {
      setLoading(false);
    }
  }, [addToast, instantMockUrl, queryText, seedDataValue, variantProposal]);

  const onSeedDataChange = useCallback((value: string) => {
    setSeedDataValue(value);
  }, []);

  const disabled = useMemo(() => seedDataValue === JSON.stringify(seedData, null, 2), [seedData, seedDataValue]);

  useEffect(() => {
    setSeedDataValue(JSON.stringify(seedData, null, 2));
  }, [seedData]);

  return (
    <Stack gap={3} flexGrow={1}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label={f({ id: 'query' })} />
        <Tab label={f({ id: 'seed-data' })} />
      </Tabs>
      {value === 0 ? (
        <QueryView
          queryId={id}
          noAnnotateDesign={noAnnotateDesign}
          queryText={queryText}
          switchToSeedData={() => {
            setValue(1);
          }}
          getViewport={getViewport}
        />
      ) : (
        <Stack flexGrow={1} gap={1}>
          <SeedData queryId={id} value={seedDataValue} onChange={onSeedDataChange} />
          <LoadingButton variant="contained" onClick={onSeedData} disabled={disabled} loading={loading}>
            {f({ id: 'seed-data' })}
          </LoadingButton>
        </Stack>
      )}
    </Stack>
  );

  // const { onMount } = useMonaco({ id: id, placeholder: 'Type here...' });
  //
  // const { ref, height, width } = useElementSize<HTMLDivElement>();
  //
  // return (
  //   <Box sx={rootSx} ref={ref}>
  //     <Editor
  //       width={width}
  //       height={height}
  //       language="graphql"
  //       options={{
  //         language: 'graphql',
  //         theme: 'default',
  //         wordWrap: 'on',
  //         'semanticHighlighting.enabled': true,
  //         lineNumbers: 'off',
  //         minimap: { enabled: false },
  //         scrollBeyondLastLine: false,
  //         scrollBeyondLastColumn: 0,
  //         fontFamily: "'JetBrains Mono'",
  //         scrollbar: {
  //           horizontal: 'hidden',
  //           vertical: 'hidden',
  //         },
  //         renderLineHighlight: 'none',
  //         overviewRulerBorder: false,
  //         glyphMargin: false,
  //         defaultColorDecorators: false,
  //         overviewRulerLanes: 0,
  //         folding: false,
  //         guides: {
  //           indentation: false,
  //         },
  //         renderWhitespace: 'none',
  //         quickSuggestions: false,
  //       }}
  //       onMount={onMount}
  //     />
  //     <FileStatus fileId={id} />
  //   </Box>
  // );
};
