import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { OrganizationsQuery } from '../../../../../gql/graphql';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { REMOVE_APP_MUTATION } from '../../../../../graphql/mutations';
import { useActiveOrganization } from '../../../../../hooks/use-active-organization';
import { useSnackStack } from '../../../../../wrappers/snack-stack-context';
import { useApplication } from '../../../../../wrappers/application-context/application-context';
import { DeRegisterEntitiesCommand } from '@xspecs/single-source-model';
import { logger } from '@xspecs/logger';
import { ErrorOutlineRounded } from '@mui/icons-material';

type AppsListItemProps = OrganizationsQuery['organizations'][0]['registeredApps'][0];

export const AppsListItem = (props: AppsListItemProps) => {
  const { id, icon, name, version, vendorName, vendorWebsite, vendorSupportUrl, error, description } = props;

  const { formatMessage: f } = useIntl();
  const { application } = useApplication();
  const [removeApp] = useMutation(REMOVE_APP_MUTATION);
  const { organization, refreshActiveOrganization } = useActiveOrganization();
  const { addToast } = useSnackStack();

  const onUninstallApp = useCallback(async () => {
    if (!application) {
      logger.warn('Application not found');
      return;
    }
    const appToUnregister = organization.registeredApps.find((app) => app.id === id);
    const entitiesToUnregister = appToUnregister?.manifest?.entities;
    if (entitiesToUnregister)
      application.model.messageBus.send(DeRegisterEntitiesCommand, {
        entities: {
          assets: entitiesToUnregister.assets?.map((asset) => ({ type: asset.type })) ?? [],
          constructs: entitiesToUnregister.constructs?.map((construct) => ({ type: construct.type })) ?? [],
        },
      });
    const response = await removeApp({
      variables: {
        args: {
          appId: id,
          organizationId: organization.id,
        },
      },
    });

    const responseError = response?.data?.removeApp?.error;
    if (responseError) {
      addToast({
        title: f({ id: responseError }),
        severity: 'error',
      });
    } else {
      await refreshActiveOrganization();
    }
  }, [
    addToast,
    application,
    f,
    id,
    organization.id,
    organization.registeredApps,
    refreshActiveOrganization,
    removeApp,
  ]);

  return (
    <Stack sx={{ width: '100%', border: '1px solid #d8d8d8', borderRadius: 2, gap: 1, p: 1 }}>
      <Stack direction="row" gap={1}>
        {icon ? <img alt={`Logo for ${name}`} width={20} src={icon} /> : null}
        {name ? <Typography variant="subtitle1">{name}</Typography> : null}
      </Stack>
      {error ? (
        <Stack direction="row" alignItems="center" gap={1}>
          <ErrorOutlineRounded color="error" />
          <Typography color="error">{f({ id: error })}</Typography>
        </Stack>
      ) : null}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, width: '100%' }}>
        <Typography variant="subtitle2">{f({ id: 'app-version' })}</Typography>
        <Typography variant="subtitle2">{version}</Typography>
        <Typography variant="subtitle2">{f({ id: 'app-vendor' })}</Typography>
        <Link
          variant="subtitle2"
          target="_blank"
          rel="noopener noreferrer"
          color="primary.main"
          underline="none"
          href={vendorWebsite ?? undefined}
        >
          {vendorName}
        </Link>
      </Box>
      <Typography variant="body2">{description ?? f({ id: 'no-description' })}</Typography>
      <Stack direction="row" gap={1}>
        <Button size="small" variant="text" onClick={onUninstallApp}>
          {f({ id: 'app-uninstall' })}
        </Button>
        <Button
          size="small"
          variant="text"
          component="a"
          href={vendorSupportUrl ?? undefined}
          target="_blank"
          rel="noopener noreferrer"
        >
          {f({ id: 'app-support' })}
        </Button>
      </Stack>
    </Stack>
  );
};
