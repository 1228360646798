import { Edge } from '../transitions/Edge';
import { Upload } from '../assets/Upload';
import { EntityBase } from '../EntityBase';
import { EntityType } from '../EntityType';
import { Preview } from '../assets/Preview';
import { NarrativeScript } from '../scripts/NarrativeScript';
import { Moment } from './Moment';
import { Interface } from './Interface';
import { Action } from './Action';
import { Narrative } from './Narrative';
import { Command } from './Command';
import { Event } from './Event';
import { Data } from './Data';
import { Process } from './Process';
import { Constraints } from './Constraints';
import { ExternalSystem } from './ExternalSystem';
import { Resolver } from './Resolver';
import { ReadModel } from './ReadModel';
import { Projection } from './Projection';
import { Gateway } from './Gateway';
import { Attachment } from '../assets/Attachment';
import { Actor } from '../assets/Actor';
import { Spec } from '../assets/Spec';
import { Doc } from '../assets/Doc';
import { Schema } from '../assets/Schema';
import { Query } from '../assets/Query';
import { Thread } from '../threads/Thread';
import { Comment } from '../threads/Comment';
import { Capability } from '../scripts/Capability';
import { ActionScript } from '../scripts/ActionScript';
import { Label } from '../assets/Label';
import { Filter } from '../Filter';
import { ConstructBase } from './ConstructBase';
import { AssetBase } from '../assets/AssetBase';
import { GqlField } from '../gql-entities/GqlField';
import { GqlOperation } from '../gql-entities/GqlOperation';
import { DynamicEntityRegistry } from '../../apps/DynamicEntityRegistry';

function findAllParentClasses(cls: any): any[] {
  const parents: any[] = [];
  let currentClass = cls,
    max = 20;
  while (--max > 0) {
    const parentClass = Object.getPrototypeOf(currentClass.prototype);
    if (!parentClass || parentClass === Object.prototype) break;
    parents.push(parentClass.constructor);
    currentClass = parentClass.constructor;
  }
  return parents;
}

type EntityBaseConstructor = new (...args: any[]) => EntityBase;

type AbstractConstructor<T = object> = abstract new (...args: any[]) => T;

export class EntityParserFactory {
  private static entityClassRegistry: Partial<Record<EntityType, EntityBaseConstructor>>;

  private static initializeEntityClassRegistry() {
    if (!this.entityClassRegistry) {
      this.entityClassRegistry = {
        [EntityType.NarrativeScript]: NarrativeScript,
        [EntityType.Moment]: Moment,
        [EntityType.Interface]: Interface,
        [EntityType.Action]: Action,
        [EntityType.Narrative]: Narrative,
        [EntityType.Command]: Command,
        [EntityType.Data]: Data,
        [EntityType.Event]: Event,
        [EntityType.Process]: Process,
        [EntityType.Constraints]: Constraints,
        [EntityType.ExternalSystem]: ExternalSystem,
        [EntityType.Resolver]: Resolver,
        [EntityType.ReadModel]: ReadModel,
        [EntityType.Projection]: Projection,
        [EntityType.Gateway]: Gateway,
        [EntityType.Attachment]: Attachment,
        [EntityType.Actor]: Actor,
        [EntityType.Spec]: Spec,
        [EntityType.Doc]: Doc,
        [EntityType.Schema]: Schema,
        [EntityType.Query]: Query,
        [EntityType.Edge]: Edge,
        [EntityType.Thread]: Thread,
        [EntityType.Comment]: Comment,
        [EntityType.Upload]: Upload,
        [EntityType.Capability]: Capability,
        [EntityType.ActionScript]: ActionScript,
        [EntityType.Label]: Label,
        [EntityType.Filter]: Filter,
        [EntityType.Preview]: Preview,
        [EntityType.GqlOperation]: GqlOperation,
        [EntityType.GqlField]: GqlField,
      };
    }
  }

  public static isInstanceOfBase<T extends EntityBase>(
    entityType: EntityType,
    baseClass: AbstractConstructor<T>,
  ): boolean {
    this.initializeEntityClassRegistry();
    const entityClass = this.entityClassRegistry[entityType];
    if (!entityClass) {
      throw new Error(`No class found for type ${entityType}`);
    }
    const parentClasses = findAllParentClasses(entityClass);
    return parentClasses.some((cls) => cls === baseClass);
  }

  public static registerEntityType(type: string, constructor: EntityBaseConstructor) {
    this.initializeEntityClassRegistry();
    this.entityClassRegistry[type] = constructor;
  }

  public static deRegisterEntityType(type: string) {
    this.initializeEntityClassRegistry();
    if (this.entityClassRegistry[type]) {
      delete this.entityClassRegistry[type];
    }
  }

  public static getEntityTypes(): (EntityType | string)[] {
    this.initializeEntityClassRegistry();
    return Object.keys(this.entityClassRegistry) as EntityType[];
  }

  public static getInstance(type: string): typeof EntityBase {
    this.initializeEntityClassRegistry();
    if (!this.entityClassRegistry[type]) throw new Error(`No parser found for type ${type}`);
    return this.entityClassRegistry[type];
  }

  public static getReferences(className: string): string[] {
    this.initializeEntityClassRegistry();
    if (!this.entityClassRegistry[className]) return [];
    const references = this.entityClassRegistry[className].references;
    return [...new Set([...this.getParentReferences(className), ...references])];
  }

  private static getParentReferences(className: string) {
    function getReferenceFor(baseClassName: string) {
      // FIXME this is a hack to remove the underscore from the class name due to esbuild shitiness
      switch (baseClassName.replace('_EntityBase', 'EntityBase')) {
        case 'EntityBase':
          return EntityBase.references;
        case 'ConstructBase':
          return ConstructBase.references;
        case 'AssetBase':
          return AssetBase.references;
        default:
          return [];
      }
    }

    return findAllParentClasses(this.entityClassRegistry[className]).flatMap((cls) => getReferenceFor(cls.name));
  }

  public static parse<T extends EntityBase>(
    data: any,
    entityType?: EntityType,
    findEntity?: (id: string) => EntityBase,
    omitReferences = false,
  ): T {
    this.initializeEntityClassRegistry();
    // TODO replace with a decorator based approach
    EntityBase.setFindFunction(findEntity!);
    const typeKey = entityType || data.type;
    if (!typeKey) throw new Error('No type specified for parsing');
    let entityClass = this.entityClassRegistry[typeKey];
    if (!entityClass && data.__dynamic) {
      entityClass = DynamicEntityRegistry.createDynamicFallbackEntity(data);
    }
    if (!entityClass) throw new Error(`No parser found for type ${typeKey}`);
    return entityClass.parse(data, omitReferences);
  }
}
