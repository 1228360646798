import { Box, Button, Icon, Stack, SxProps, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Modal } from '../modal/modal';
import { useIntl } from 'react-intl';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useApplication } from '../../wrappers/application-context/application-context';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { useNavigate, useParams } from 'react-router-dom';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';

export const DeleteAssetModal = () => {
  const { formatMessage: f } = useIntl();
  const navigate = useNavigate();
  const { entityId } = useParams();
  const { workspace } = useActiveWorkspace();
  const { organization } = useActiveOrganization();

  const showDeleteAssetModal = useSingleSourceStore.use.showDeleteAssetModal();
  const setShowDeleteAssetModal = useSingleSourceStore.use.setShowDeleteAssetModal();
  const assetToDelete = useSingleSourceStore.use.assetToDelete();
  const setAssetToDelete = useSingleSourceStore.use.setAssetToDelete();
  const assetExplorer = useSingleSourceStore.use.assetExplorer();
  const { application } = useApplication();

  const onClose = useCallback(() => {
    setShowDeleteAssetModal(false);
  }, [setShowDeleteAssetModal]);

  const onDelete = useCallback(() => {
    if (!assetToDelete) return;
    application?.model.sidebarInteractor.deleteAsset(assetToDelete);
    setShowDeleteAssetModal(false);
    setAssetToDelete(undefined);

    if (assetToDelete === entityId) {
      navigate(
        getRoutePathFromParams(RoutePaths.Workspace, {
          organizationName: organization.name,
          workspaceName: workspace.name,
        }),
      );
    }
  }, [
    application?.model.sidebarInteractor,
    assetToDelete,
    entityId,
    navigate,
    organization.name,
    setAssetToDelete,
    setShowDeleteAssetModal,
    workspace.name,
  ]);

  const assetUsages = useMemo(() => {
    const asset = assetExplorer.results.find((asset) => asset.id === assetToDelete);
    if (!asset) return null;
    return asset.usages.length;
  }, [assetExplorer.results, assetToDelete]);

  return (
    <Modal
      id="DeleteAssetModal"
      isOpen={showDeleteAssetModal}
      onClose={onClose}
      headerSxOverrides={headerSxOverrides}
      rootSxOverrides={rootSxOverrides}
      headerStartContent={
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon sx={{ fontSize: 32 }} color="error" component={DeleteOutlineOutlinedIcon} />
          <Typography variant="h6">{f({ id: 'delete-asset' })}</Typography>
        </Stack>
      }
    >
      <Typography variant="body1">
        {f({ id: 'are-you-sure-to-delete-asset' })} <br /> {f({ id: 'delete-asset-warning' })} <br />{' '}
        {f({ id: 'asset-used-in' }, { count: assetUsages })}
      </Typography>
      <Box sx={buttonsWrapperSx}>
        <Button variant="outlined" onClick={onClose}>
          {f({ id: 'cancel' })}
        </Button>
        <Button variant="contained" color="error" onClick={onDelete}>
          {f({ id: 'yes-delete' })}
        </Button>
      </Box>
    </Modal>
  );
};

const headerSxOverrides: SxProps = {
  justifyContent: 'space-between',
};
const rootSxOverrides: SxProps = { width: '400px', p: 2, gap: 3 };
const buttonsWrapperSx: SxProps = { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 1 };
