import { Box, Stack } from '@mui/material';
import { Allotment } from 'allotment';
import { useOutlet, useParams } from 'react-router-dom';
import { SingleSourceModel } from '../../components/single-source-model';
import { SingleSourceModelWrapper } from '../../wrappers/single-source-model-wrapper/single-source-model-wrapper';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { SingleSourceFilters } from '../../components/single-source-model/filters/single-source-filters';
import { getToken } from '../../utils/getToken';
import { useApplication } from '../../wrappers/application-context/application-context';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useSingleSourceStore } from '../../store/single-source-store/single-source-store';
import { useAuth } from '../../auth';
import { Sidebar } from '../../components/sidebar/sidebar';

export const WorkspaceRoute = () => {
  const { entityId } = useParams();

  const entityBaseId = entityId ? entityId.split('-')[0] : '';
  const outlet = useOutlet({ entityId: entityBaseId });
  const ref = useRef<any>();
  const { application } = useApplication();

  const assetExplorer = useSingleSourceStore.use.assetExplorer();

  const { workspace } = useActiveWorkspace();
  const isLoaded = useSingleSourceStore.use.isLoaded();
  const collapseSidebar = useSingleSourceStore.use.collapseSidebar();
  const setCollapseSidebar = useSingleSourceStore.use.setCollapseSidebar();
  const { user } = useAuth();

  const onDragEnd = useCallback((e) => {
    localStorage.setItem('allotments', JSON.stringify(e));
  }, []);

  const defaultSizes = useMemo(() => {
    const DEFAULT = [5, 90, 20];
    const x = JSON.parse(localStorage.getItem('allotments') ?? 'null');
    if (!x || x.length !== DEFAULT.length) localStorage.setItem('allotments', JSON.stringify(DEFAULT));
    return x ?? DEFAULT;
  }, []);

  useEffect(() => {
    const token = getToken();
    if (isLoaded && workspace && application && token) {
      application.changeContext({
        token,
        user,
        scopes: [workspace.id],
      });
    }
  }, [application, isLoaded, user, workspace]);

  const onCollapseSidebar = useCallback(() => {
    setCollapseSidebar(!collapseSidebar);
  }, [collapseSidebar, setCollapseSidebar]);

  return (
    <Stack height="100%" width="100%">
      <SingleSourceModelWrapper>
        <SingleSourceFilters />
        <Allotment
          separator
          defaultSizes={defaultSizes}
          proportionalLayout={true}
          vertical={false}
          ref={ref}
          onDragEnd={onDragEnd}
        >
          <Allotment.Pane minSize={collapseSidebar ? 64 : 300} maxSize={collapseSidebar ? 64 : 500}>
            <Sidebar
              assetExplorer={assetExplorer}
              profile={user}
              collapsed={collapseSidebar}
              onCollapse={onCollapseSidebar}
            />
          </Allotment.Pane>
          <Allotment.Pane>
            <SingleSourceModel />
          </Allotment.Pane>
          <Allotment.Pane visible={Boolean(outlet)} minSize={200}>
            {outlet ? (
              <Box height="100%" width="100%">
                {outlet}
              </Box>
            ) : null}
          </Allotment.Pane>
        </Allotment>
      </SingleSourceModelWrapper>
    </Stack>
  );
};
