import { NavBar } from '../../components/nav-bar/nav-bar';
import { Box } from '@mui/material';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { ORGANIZATIONS_QUERY } from '../../graphql/queries';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { navBarOverridePropsVar } from '../../state/state';
import { SearchModal } from '../../components/search-modal/search-modal';
import { WorkspaceSettingsModal } from '../../components/workspace-settings-modal/workspace-settings-modal';
import { OrganizationSettingsModal } from '../../components/organization-settings-modal/organization-settings-modal';
import { getRoutePathFromParams, RoutePaths } from '../../config/route-paths/route-paths';
import { InviteUsersModal } from '../../components/invite-users-modal/invite-users-modal';
import { Loading } from '../../components/loading/loading';
import { KeyboardShortcutsModal } from '../../components/keyboard-shortcuts-modal/keyboard-shortcuts-modal';
import { useActiveWorkspace } from '../../hooks/use-active-workspace';
import { useActiveOrganization } from '../../hooks/use-active-organization';
import { getToken } from '../../utils/getToken';
import { singleSourceStore } from '../../store/single-source-store/single-source-store';
import { useApplication } from '../../wrappers/application-context/application-context';
import { useAuth } from '../../auth';
import { logger } from '@xspecs/logger';
import { Analytics } from '@xspecs/single-source-model';

export const OrganizationRoute = () => {
  const navBarOverrideProps = useReactiveVar(navBarOverridePropsVar);
  const { organization, setOrganization } = useActiveOrganization();
  const { workspace, setWorkspace } = useActiveWorkspace();

  const { organizationName, workspaceName } = useParams<{ organizationName: string; workspaceName?: string }>();

  const [isReady, setIsReady] = useState(false);
  const isInitializedRef = useRef(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { application } = useApplication();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();

  const [fetchOrganizations, { loading }] = useLazyQuery(ORGANIZATIONS_QUERY);

  const resolveOrganizationAndWorkspace = useCallback(async () => {
    if (organizationName === organization?.name && workspaceName === workspace?.name) return;
    if (!organizationName) {
      return;
    }
    const response = await fetchOrganizations();
    if (!response.data) {
      logger.error('Failed to fetch organizations');
      return;
    }
    const { organizations } = response.data;
    const foundOrganization = organizations.find((organization) => organization.name === organizationName);
    if (!foundOrganization) {
      navigate(RoutePaths.ChooseOrganization);
      return;
    }
    localStorage.setItem('workspaces', JSON.stringify(foundOrganization.workspaces.map((w) => w.id)));
    setOrganization(foundOrganization);

    setIsReady(true);

    if (foundOrganization.workspaces.length === 0) {
      navigate(getRoutePathFromParams(RoutePaths.CreateWorkspace, { organizationName }));
      return;
    }

    const foundWorkspace = workspaceName
      ? foundOrganization.workspaces.find((workspace) => workspace.name === workspaceName)
      : foundOrganization.workspaces[0];

    setWorkspace(foundWorkspace);

    if (location?.state?.shouldNavigate && foundWorkspace) {
      navigate(getRoutePathFromParams(RoutePaths.Workspace, { organizationName, workspaceName: foundWorkspace.name }));
    }
  }, [
    organizationName,
    organization?.name,
    workspaceName,
    workspace?.name,
    fetchOrganizations,
    setOrganization,
    setWorkspace,
    location?.state?.shouldNavigate,
    navigate,
  ]);

  useEffect(() => {
    resolveOrganizationAndWorkspace().catch(() => {});
  }, [resolveOrganizationAndWorkspace]);

  const version = useMemo(() => searchParams.get('version') || undefined, [searchParams]);

  useEffect(() => {
    if (!organization || !organization.singleSourceModel) return;

    const token = getToken();
    if (!application || !token) {
      logger.error('Application not initialized');
      return;
    }

    if (isInitializedRef.current) {
      return;
    }
    application.start({
      token,
      user,
      scopes: [workspace.id],
      modelId: organization.singleSourceModel.id,
      version,
    });
    singleSourceStore.getState().setSingleSourceModel(application.model);
    isInitializedRef.current = true;
  }, [application, organization, user, version, workspace]);

  useEffect(() => {
    if (organization) {
      Analytics.updateOrganization(organization.name);
    }

    return () => {
      Analytics.updateOrganization(undefined);
    };
  }, [organization]);

  useEffect(() => {
    if (workspace) {
      Analytics.updateWorkspace(workspace.name);
    }

    return () => {
      Analytics.updateWorkspace(undefined);
    };
  }, [workspace]);

  return (
    <>
      <Box data-testid="home-route" sx={{ height: '100%', width: '100%' }}>
        {loading || !isReady ? (
          <Loading rootProps={{ sx: { height: '100%', width: '100%' } }} />
        ) : (
          <Box height="100%" display="flex" flexDirection="column">
            <NavBar isLoggedIn hasOrganizations {...navBarOverrideProps} />
            <Box height="calc(100% - 64px)">
              <Outlet />
            </Box>
            <OrganizationSettingsModal />
            <WorkspaceSettingsModal />
            <InviteUsersModal />
            <KeyboardShortcutsModal />
          </Box>
        )}
        <SearchModal />
      </Box>
    </>
  );
};
