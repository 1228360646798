import { SingleSourceModel } from '../SingleSourceModel';
import { Analytics, AppTypeEvent } from '../analytics/analytics';
import {
  Connection,
  EdgeChange,
  EdgeSelectionChange,
  Node,
  NodeAddChange,
  NodeChange,
  NodeSelectionChange,
  ResizeParamsWithDirection,
  XYPosition,
} from '@xyflow/react'; // TODO rethink these imports
import { sid } from '@xspecs/short-id';
import { CreateThreadCommand } from '../commands/comments/CreateThreadCommand';
import { MoveEntitiesCommand } from '../commands/entities/MoveEntitiesCommand';
import { Position, WithId } from '../types';
import { InteractorResponse, Mode, SingleSourceStore } from './types';
import { DEBUG_CONFIG } from '../debug-config';
import { logger } from '@xspecs/logger';
import { Awareness } from 'y-protocols/awareness';
import { EntityBase } from '../entities/EntityBase';
import { AssetTypes } from '../entities/assets/AssetTypes';
import { Attachment } from '../entities/assets/Attachment';
import { AttachmentType, EntityType, isValidAttachmentType, isValidEntityType } from '../entities/EntityType';
import { Thread } from '../entities/threads/Thread';
import { UploadType } from '../entities/assets/Upload';
import { IStore } from '../data/Store';
import { AddEntityCommand } from '../commands/entities/AddEntityCommand';
import { DeselectAllEntitiesCommand } from '../commands/selections/DeselectAllEntitiesCommand';
import { UpdateEntitiesSelectionsCommand } from '../commands/selections/UpdateEntitiesSelectionsCommand';
import { AddRelationshipCommand } from '../commands/relationships/AddRelationshipCommand';
import { DragEntitiesCommand } from '../commands/entities/DragEntitiesCommand';
import { SelectAllEntitiesCommand } from '../commands/selections/SelectAllEntitiesCommand';
import { ClearErrorCommand } from '../commands/errors/ClearErrorCommand';
import { ToggleScriptVisibilityCommand } from '../commands/scripts/ToggleScriptVisibilityCommand';
import { TestCodeEvent } from '../TestCodeHandler';
import { DeleteEntitiesCommand } from '../commands/entities/DeleteEntitiesCommand';
import { CommandError } from '../ErrorStore';
import { ToggleAttachmentsExpandedCommand } from '../commands/attachments/ToggleAttachmentsExpanded';
import { Narrative } from '../entities/constructs/Narrative';
import { Action } from '../entities/constructs/Action';
import { ResizeEntityCommand } from '../commands/entities/ResizeEntityCommand';
import { CreateUploadCommand } from '../commands/entities/CreateUploadCommand';

export class CanvasInteractor {
  private dxDy: XYPosition = { x: 0, y: 0 };
  private mousePosition: XYPosition = { x: 0, y: 0 };
  private dragStartPosition: XYPosition = { x: 0, y: 0 };
  private flowPosition: XYPosition = { x: 0, y: 0 };

  private get store(): SingleSourceStore {
    return this.storeWrapper.getState();
  }

  constructor(
    private readonly model: SingleSourceModel,
    private readonly storeWrapper: IStore,
    private readonly awareness: Awareness,
  ) {}

  private get analytics() {
    return Analytics.getInstance();
  }

  private dragDropHandler(entity: EntityBase, mousePosition: XYPosition) {
    this.log('dragDropHandler => move');
    this.model.messageBus.send(MoveEntitiesCommand, {
      entityIds: [entity.id],
      dxDy: this.dxDy,
      cursorPosition: mousePosition,
    });
  }

  onDrop(flowPosition: XYPosition, type: string, userSub: string): InteractorResponse {
    this.resetDxDy();

    if (isValidEntityType(type) || this.model.palette.isValidEntityType(type)) {
      if (AssetTypes.isAsset(type) || this.model.palette.isAttachmentInPalette(type)) {
        this.model.messageBus.send(DeselectAllEntitiesCommand, {});
        const id = sid();
        this.model.messageBus.send(AddEntityCommand, {
          id: id,
          type: EntityType.Attachment,
          subType: type as unknown as AttachmentType,
          position: flowPosition,
          name: '',
        });
        const entity = this.model.entities.get(id)!;
        this.openUploadFileModal(entity);
      } else {
        if (type !== EntityType.Attachment) {
          this.model.messageBus.send(DeselectAllEntitiesCommand, {});
          const id = sid();
          if (type === EntityType.Thread) {
            this.model.messageBus.send(AddEntityCommand, {
              id,
              type: EntityType.Thread,
              position: flowPosition,
              name: '',
              createdBy: userSub,
            });
          } else {
            this.model.messageBus.send(AddEntityCommand, {
              id,
              type: type as any,
              position: flowPosition,
              name: '',
            });
          }
          const entity = this.model.entities.get(id)!;
          this.dragDropHandler(entity, flowPosition);
          if (entity instanceof Thread) {
            return {
              action: 'fitView',
              params: { nodes: [{ id: entity.id }], duration: 500, padding: 24 },
            };
          }
        }
      }
    }
    this.store.setSelectionMode();
  }

  onDropFiles(
    flowPosition: XYPosition,
    file: File,
    url: string,
    assetId: string,
    metadata?: { width: number; height: number },
  ) {
    const type = file.type.startsWith('image') ? UploadType.Image : UploadType.File;

    const attachmentId = sid();
    this.model.messageBus.send(CreateUploadCommand, {
      assetId,
      id: attachmentId,
      name: file.name,
      position: flowPosition,
      type,
      url: url,
      metadata,
    });
  }

  openUploadFileModal(entity: EntityBase) {
    if (entity instanceof Attachment && entity.subType === AttachmentType.Upload && !entity.asset) {
      this.store.setShowUploadFileModal(entity.id);
    }
  }

  onMouseUp(flowPosition: XYPosition, userSub: string) {
    this.log('onMouseUp');
    this.resetDxDy();
    const constructToInsert = this.store.constructToInsert;
    const mode = this.store.mode;
    if (constructToInsert && mode === Mode.INSERT_CONSTRUCT) {
      if (AssetTypes.isAsset(constructToInsert) || this.model.palette.isAttachmentInPalette(constructToInsert)) {
        const id = sid();
        this.model.messageBus.send(AddEntityCommand, {
          id: id,
          type: EntityType.Attachment,
          subType: constructToInsert as unknown as AttachmentType,
          position: flowPosition,
          name: '',
        });
        const entity = this.model.entities.get(id)!;
        this.openUploadFileModal(entity);
      } else {
        if (constructToInsert !== EntityType.Attachment && constructToInsert !== EntityType.Thread) {
          this.model.messageBus.send(AddEntityCommand, {
            id: sid(),
            type: constructToInsert as any,
            position: flowPosition,
            name: '',
          });
        }
      }
      this.store.setSelectionMode();
      return;
    }

    if (mode === Mode.INSERT_SCRIPT) {
      const id = sid();
      this.model.messageBus.send(AddEntityCommand, {
        id,
        name: '',
        type: EntityType.Narrative,
        position: flowPosition,
      });

      this.store.setSelectionMode();
      return;
    }

    if (
      mode === Mode.INSERT_ACTION ||
      mode === Mode.INSERT_INTERFACE ||
      mode === Mode.INSERT_MOMENT ||
      mode === Mode.INSERT_CAPABILITY
    ) {
      const typeMap = {
        [Mode.INSERT_ACTION]: EntityType.Action,
        [Mode.INSERT_INTERFACE]: EntityType.Interface,
        [Mode.INSERT_MOMENT]: EntityType.Moment,
        [Mode.INSERT_CAPABILITY]: EntityType.Capability,
      };
      const type = typeMap[mode];
      if (type !== EntityType.Attachment && type !== EntityType.Thread) {
        const id = sid();
        this.model.messageBus.send(AddEntityCommand, {
          id: id,
          name: '',
          type: type,
          position: flowPosition,
        });
        this.store.setSelectionMode();
        this.dragDropHandler(this.model.entityRepository.get(id)!, flowPosition);
      }
      return;
    }

    if (mode === Mode.INSERT_ACTOR || mode === Mode.INSERT_SPEC) {
      const typeMap = {
        [Mode.INSERT_ACTOR]: EntityType.Actor,
        [Mode.INSERT_SPEC]: EntityType.Spec,
      };
      const id = sid();
      this.model.messageBus.send(AddEntityCommand, {
        id: id,
        name: '',
        type: EntityType.Attachment,
        subType: typeMap[mode] as unknown as AttachmentType,
        position: flowPosition,
      });
      const entity = this.model.entities.get(id)!;
      this.openUploadFileModal(entity);
      this.store.setSelectionMode();
      return;
    }

    if (mode === Mode.INSERT_THREAD) {
      const threadId = sid();
      this.model.messageBus.send(CreateThreadCommand, {
        id: threadId,
        userId: userSub,
        parent: undefined!,
        position: flowPosition,
        scopes: [],
        activeUser: this.model.modelFile.activeUser,
      });
      this.analytics.trackEvent(AppTypeEvent.CommentThreadAdded, threadId);
      this.store.setSelectionMode();
      return {
        action: 'fitView',
        params: { nodes: [{ id: threadId }], duration: 500, padding: 24 },
      };
    }

    this.log('onMouseUp => clearing');
  }

  onNodeChanges(nodeChanges: NodeChange[]) {
    if (nodeChanges.length === 0) return;
    // can we can safely assume that all changes are of the same type? Assuming yes for now
    const changeType = nodeChanges[0].type;
    if (changeType === 'select') {
      // TODO perhaps we should ignore selections here and instead translate intent depending on what the user clicked?
      this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
        entitySelections: nodeChanges.filter((nodeChange) => nodeChange.type === 'select') as NodeSelectionChange[],
      });
      return;
    }

    if (changeType === 'add') {
      nodeChanges
        .filter((n) => n.type == 'add')
        .forEach((change: NodeAddChange) => {
          const { item } = change;
          if (!isValidAttachmentType(item.type)) return;
          this.model.messageBus.send(AddEntityCommand, {
            id: item.id,
            name: (item.data.name as string) ?? '',
            type: item.type as any,
            position: item.position,
          });
          const entity = this.model.entities.get(item.id)!;
        });
    }
  }

  onMouseLeave() {
    this.deleteCursor();
  }

  // ## ================== EDGE INTERACTIONS ================== ##

  onConnect(connection: Connection): InteractorResponse {
    if (connection.source && connection.target) {
      const ret = this.model.messageBus.send(AddRelationshipCommand, {
        sourceId: connection.source,
        targetId: connection.target,
      });
      if (ret instanceof Error) {
        return {
          action: 'snackbar',
          params: { message: ret.message, severity: 'warning' },
        };
      }
    }
  }

  onConnectStart(params) {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
  }

  onEdgesChange(edgeChanges: EdgeChange[]) {
    if (edgeChanges.length === 0) return;
    const type = edgeChanges[0].type;
    if (type === 'select') {
      this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
        entitySelections: edgeChanges as EdgeSelectionChange[],
      });
      return;
    }
  }

  // ## ================== CUT/COPY/PASTE + KEYBOARD SHORTCUTS ================== ##

  undo() {
    this.model.undo();
  }

  redo() {
    this.model.redo();
  }

  onCut() {
    void this.model.clipboard.cut();
  }

  onCopy() {
    void this.model.clipboard.copy();
  }

  onPaste() {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
    this.model.clipboard.paste(this.flowPosition).then((pasted) => {
      this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
        entitySelections: pasted.map((entity) => ({ id: entity.id, selected: true })),
      });
    });
  }

  selectEntity(id: string) {
    this.model.messageBus.send(UpdateEntitiesSelectionsCommand, { entitySelections: [{ id, selected: true }] });
  }

  onDuplicate() {
    void this.onCopy();
    void this.onPaste();
  }

  onSelectAll() {
    this.model.messageBus.send(SelectAllEntitiesCommand, {});
  }

  onDeSelectAll() {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
  }

  onEscape() {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
    this.store.setSelectionMode();
  }

  // ## ================== HOVER TRACKING ================== ##

  // ## ================== MOUSE TRACKING ================== ##

  onDragOver(mousePosition: XYPosition, flowPosition: XYPosition) {
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
  }

  onNodeClick(mousePosition: Position, flowPosition: Position, id: string, userSub: string) {
    this.log('onNodeClick');
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    const entity = this.model.entities.get(id);
    if (!entity) return;
    if (entity.type === EntityType.Attachment && !(entity as Attachment).asset) return;
    this.onMouseUp(flowPosition, userSub);
  }

  onNodeDragStart(mousePosition: XYPosition, flowPosition: XYPosition, nodes: Node[]) {
    this.resetDxDy();
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.setDragStartPosition(flowPosition, 'onNodeDragStart');
    this.model.messageBus.send(UpdateEntitiesSelectionsCommand, {
      entitySelections: nodes.map((n) => ({ id: n.id, selected: true })),
    });
  }

  onNodeDrag(mousePosition: XYPosition, flowPosition: XYPosition, nodes: Node[], params: { isModPressed: boolean }) {
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.setCursor(flowPosition);
    this.setDxDy(flowPosition, 'onNodeDrag');
    this.model.messageBus.send(DragEntitiesCommand, {
      entityIds: nodes.map((n) => n.id),
      dxDy: this.dxDy,
      isModPressed: params.isModPressed,
    });
  }

  onNodeDragStop(
    mousePosition: XYPosition,
    flowPosition: XYPosition,
    nodes: Node[],
    params: { isModPressed: boolean },
  ) {
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.model.messageBus.send(MoveEntitiesCommand, {
      entityIds: nodes.map((c) => (c as WithId).id),
      dxDy: this.dxDy,
      isModPressed: params.isModPressed,
      cursorPosition: flowPosition,
    });
    this.resetDxDy();
  }

  onMouseMove(mousePosition: XYPosition, flowPosition: XYPosition) {
    this.setMouseAndFlowPosition(mousePosition, flowPosition);
    this.setCursor(flowPosition);
  }

  toggleEntityVisibilityExpanded(id: string) {
    const entity = this.model.entityRepository.get(id);
    if (entity instanceof Narrative || entity instanceof Action) {
      this.model.messageBus.send(ToggleScriptVisibilityCommand, { entityId: id });
    } else {
      this.model.messageBus.send(ToggleAttachmentsExpandedCommand, { id });
    }
  }

  private setMouseAndFlowPosition(mousePosition: XYPosition, flowPosition: XYPosition) {
    this.mousePosition = mousePosition;
    this.flowPosition = flowPosition;
  }

  private setDxDy(flowPosition: XYPosition, caller: string) {
    this.dxDy = {
      x: flowPosition.x - this.dragStartPosition.x,
      y: flowPosition.y - this.dragStartPosition.y,
    };
    this.log(`${caller} => setDxDy`);
  }

  private resetDxDy() {
    this.dxDy = { x: 0, y: 0 };
    this.clearDdxDy();
    this.log(`resetDxDy`);
  }

  private setDragStartPosition(dragStartPosition: XYPosition, caller: string) {
    this.dragStartPosition = dragStartPosition;
    this.log(`${caller} => setDragStartPosition`);
  }

  // ## ================== AWARENESS ================== ##

  private clearDdxDy() {
    this.awareness.setLocalStateField('changeType', 'position');
    this.awareness.setLocalStateField('dxDy', null);
  }

  private setCursor(position: XYPosition) {
    this.awareness.setLocalStateField('changeType', 'position');
    this.awareness.setLocalStateField('position', position);
    this.awareness.setLocalStateField('dxDy', this.dxDy);
  }

  private deleteCursor() {
    this.awareness.setLocalStateField('changeType', 'position');
    this.awareness.setLocalStateField('position', null);
    this.awareness.setLocalStateField('dxDy', null);
  }

  // ## ================== SELECTION ================== ##

  onSelectionChange(selection: any) {
    if (DEBUG_CONFIG.interactor) logger.log('onSelectionChange', selection);
  }

  onSelectionStart(event: React.MouseEvent<Element, MouseEvent>) {
    this.model.messageBus.send(DeselectAllEntitiesCommand, {});
  }

  onSelectionEnd(event: React.MouseEvent<Element, MouseEvent>) {
    if (DEBUG_CONFIG.interactor) logger.log('onSelectionEnd');
  }

  // ## ================== OTHER ================== ##
  log(caller?: string, min?: boolean) {
    if (!DEBUG_CONFIG.interactor) return;
    logger.log(
      `\n${caller}`,
      min ?? `\n  dragStartPosition: ${this.dragStartPosition.x}, ${this.dragStartPosition.y}`,
      min ?? `\n  flowPosition: ${this.flowPosition.x}, ${this.flowPosition.y}`,
      min ?? `\n  dxDy: ${this.dxDy.x}, ${this.dxDy.y}`,
      min ?? `\n  mousePosition: ${this.mousePosition.x}, ${this.mousePosition.y}`,
    );
  }

  clearError(errors: CommandError | CommandError[]) {
    this.model.messageBus.send(ClearErrorCommand, { errors: errors });
  }

  onDelete() {
    this.model.messageBus.send(DeleteEntitiesCommand, {});
  }

  onTestCode() {
    this.model.messageBus.handle(new TestCodeEvent());
  }

  resizeEntity(id: string, params: ResizeParamsWithDirection) {
    this.model.messageBus.send(ResizeEntityCommand, {
      entityId: id,
      width: params.width,
      height: params.height,
      x: params.x,
      y: params.y,
    });
  }
}
