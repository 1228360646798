import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  SxProps,
  Typography,
  Tooltip,
  TooltipProps,
  Box,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { AttachmentIcon } from '../../single-source-model/canvas/attachments/attachment-icon/attachment-icon';
import { Theme } from '@mui/system';
import { AssetUsageItem } from './asset-usage-item';
import { FloatingMenu, useFloatingMenu } from '../../single-source-model/canvas/floating-menu/floating-menu';
import { useIntl } from 'react-intl';
import { FloatingPortal } from '@floating-ui/react';
import { AssetExplorerItem } from '@xspecs/single-source-model/src/read-models/asset-explorer/AssetExplorerItem';

type AssetAccordionProps = AssetExplorerItem;

export const AssetAccordion = (props: AssetAccordionProps) => {
  const { id, name, type, uploadType, url, usages } = props;

  const { formatMessage: f } = useIntl();

  const {
    context,
    floatingProps,
    floatingRef,
    floatingStyles,
    referenceProps,
    referenceRef,
    showFloating,
    arrowRef,
    onManageLabels,
  } = useFloatingMenu();

  const hasUsages = usages?.length > 0;

  return (
    <Accordion slotProps={{ heading: { component: 'h4' } }} disableGutters={true} sx={accordionSx}>
      <AccordionSummary
        expandIcon={
          hasUsages ? (
            <Tooltip title={f({ id: 'show-usage' })} placement="top" slotProps={tooltipSlotProps}>
              <ExpandMore fontSize="small" color="action" />
            </Tooltip>
          ) : null
        }
        aria-controls={`asset-${name}`}
        id={`asset-${name}`}
        {...referenceProps}
        ref={referenceRef}
        sx={accordionSummarySx}
      >
        <Stack gap={1} direction="row" alignItems="center" sx={accordionContentSx}>
          <Tooltip title={name}>
            <Box>
              <AttachmentIcon size={24} type={type} uploadType={uploadType} url={url || ''} />
            </Box>
          </Tooltip>
          <Typography variant="body2" color="textPrimary" sx={assetNameSx}>
            {name}
          </Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={accordionDetailsSx} hidden={!hasUsages}>
        <Stack>
          {usages.map((usage) => (
            <AssetUsageItem key={usage.name} {...usage} />
          ))}
        </Stack>
      </AccordionDetails>
      {showFloating ? (
        <FloatingPortal>
          <FloatingMenu
            ref={floatingRef}
            id={id}
            styles={floatingStyles}
            arrowRef={arrowRef}
            context={context}
            type={type}
            disableLabels
            name={name}
            onManageLabels={onManageLabels}
            labels={[]}
            withDeleteAsset
            {...floatingProps}
          />
        </FloatingPortal>
      ) : null}
    </Accordion>
  );
};

const accordionSx: SxProps<Theme> = {
  boxShadow: 'none',
  background: (theme) => theme.palette.grey[50],
  position: 'relative',
  overflow: 'visible',
  '&:before': { display: 'none' },
};

const accordionDetailsSx: SxProps<Theme> = { pt: 0, pr: 0.5, pb: 1, pl: 3.5 };

const tooltipSlotProps: TooltipProps['slotProps'] = {
  popper: {
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -14],
        },
      },
    ],
  },
};

const accordionSummarySx: SxProps<Theme> = {
  '& .MuiAccordionSummary-root': {
    justifyContent: 'space-between',
  },
  '& .MuiAccordionSummary-content': {
    width: '90%',
  },
};

const assetNameSx: SxProps<Theme> = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '80%',
};

const accordionContentSx: SxProps<Theme> = { width: '100%' };
