import './wdyr';

import { createRoot } from 'react-dom/client';
import { App } from './App';
import './sentry';
import './dayjs';
import { Analytics, Application } from '@xspecs/single-source-model';
import { ApplicationProvider } from './wrappers/application-context/application-context';
import { zustandStore } from './zustand-store';

const application = Application.getInstance(zustandStore);
Analytics.setInstance(window, undefined, undefined);

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Root element not found');

const root = createRoot(rootElement);
root.render(
  <ApplicationProvider application={application}>
    <App />
  </ApplicationProvider>,
);
