import { CommandBase, IParams } from '../framework/CommandBase';
import { EventBase } from '../framework/EventBase';
import { EntityBase } from '../../entities/EntityBase';
import { EntityType } from '../../entities/EntityType';
import { ScriptBase } from '../../entities/scripts/ScriptBase';
import { CommandError } from '../../ErrorStore';
import { Preview } from '../../entities/assets/Preview';

interface ScriptVisibilityToggledParams extends IParams {
  entityId: string;
  affectedIds: string[];
  state: boolean;
}

export class ScriptVisibilityToggledEvent extends EventBase {
  static eventType = 'ScriptVisibilityToggledEvent';

  constructor(
    public readonly params: ScriptVisibilityToggledParams,
    public readonly source = ToggleScriptVisibilityCommand,
  ) {
    super();
  }
}

interface ToggleScriptVisibilityParams extends IParams {
  entityId: string;
}

export class ToggleScriptVisibilityCommand extends CommandBase<ToggleScriptVisibilityParams> {
  execute(params: ToggleScriptVisibilityParams): ScriptVisibilityToggledEvent | CommandError {
    const entity = this.model.entityRepository.get<EntityBase>(params.entityId);
    if (!entity) return CommandError.of(new Error(`Entity with id ${params.entityId} not found`), 'error');
    if (![EntityType.Narrative, EntityType.Action].includes(entity.type as EntityType))
      return CommandError.of(
        new Error(`Entity id ${params.entityId} is not a construct that supports script`),
        'error',
      );
    const script = this.model.entityRepository.get<ScriptBase>(`${entity.id}_script`)!;
    script.isOpen = !script.isOpen;
    script.isVisible = script.isOpen;
    this.model.entityRepository.update(script);
    const affectedIds: Set<string> = new Set();
    const postHide: Set<EntityBase> = new Set();
    this.model.entityRepository.getAllUniqueChildren([script.id]).forEach((child) => {
      if (script === child) return;
      if (script.isOpen && (child instanceof ScriptBase || child instanceof Preview)) {
        child.isVisible = child.isOpen;
        if (!child.isOpen) child.children.forEach((c) => postHide.add(c));
      } else {
        child.isVisible = script.isVisible;
      }
      postHide.forEach((c) => {
        c.hide();
        this.model.entityRepository.update(c);
        affectedIds.add(c.id);
      });
      affectedIds.add(child.id);
      this.model.entityRepository.update(child);
    });
    this.model.entityRepository.update(script);
    return new ScriptVisibilityToggledEvent({
      entityId: entity.id,
      affectedIds: Array.from(affectedIds),
      state: entity.isVisible,
    });
  }
}
